import React from "react";
import { Link } from "react-router-dom";
import {
  createStyles,
  withStyles,
  Button,
  Typography,
} from "@material-ui/core";

import incorrectLightExample from "../images/photos/examples/01_Light_Wrong.png";
import PicError from "../components/PicError";
import PatchCaptureHeader from "../components/PatchCaptureHeader";

import { colors } from "../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    lineHeight: "20px",
    letterSpacing: 0,
    paddingBottom: 48,
  },
  image: {
    marginTop: 24,
    marginBottom: 8,
    width: 270,
    height: 307,
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 24,
    marginBottom: 48,
  },
  footerLink: {
    marginTop: 24,
    color: colors.black,
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
});

function BeforeYouStartScreen({ classes }: any) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();

  return (
    <div className={classes.content}>
      <PatchCaptureHeader />
      <Typography variant="h3">{t("capture.title")}</Typography>
      <Typography variant="body1">{t("capture.cameraFlash")}</Typography>

      <img
        className={classes.image}
        src={incorrectLightExample}
        alt="Incorrect Light"
        width="270"
        height="307"
      />

      <PicError error={t("capture.error")} />

      <div className={classes.footer}>
        <Button
          className={classes.cta}
          component={Link}
          to="/before-you-start-2"
        >
          {t("capture.next")}
        </Button>
        <Link
          to="/how-to-use/sweat-patch-overview"
          className={classes.footerLink}
        >
          {t("capture.howToApply")}
        </Link>
      </div>
    </div>
  );
}

export default withStyles(styles)(BeforeYouStartScreen);
