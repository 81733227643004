import React from "react";
import { Typography, withStyles, createStyles } from "@material-ui/core";
import FAQ from "./FAQ";
import ReactGA from "react-ga";

import { colors } from "../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  root: {
    width: "100%",
    textAlign: "center",
  },
  modal: {
    textAlign: "left",
    width: "100%",
    height: "100vh",
    position: "absolute",
    backgroundColor: "#FFF",
    top: 0,
    left: 0,
    zIndex: 10,
  },
  content: {
    margin: 24,
  },
  link: {
    color: colors.black,
    border: "none",
    textDecoration: "underline",
    background: "none",
  },
});

function TroubleshootIssues({ classes }: any) {
  const [visible, setVisibility] = React.useState(false);
  const { t } = useTranslation();

  const questions = [
    [t("faqs.question7"), t("faqs.answer7")],
    [t("faqs.question6"), t("faqs.answer6")],
    [t("faqs.question3"), t("faqs.answer3")],
  ];

  React.useEffect(() => {
    if (visible) {
      window.scrollTo(0, 0);
      ReactGA.modalview("troubleshoot-issues");
    }
  }, [visible]);

  return (
    <div className={classes.root}>
      <button
        className={classes.link}
        onClick={(e) => {
          setVisibility(true);
          e.preventDefault();
        }}
      >
        {t("troubleshoot.issues")}
      </button>
      {visible && (
        <div className={classes.modal}>
          <div className={classes.content}>
            <Typography variant="h4">{t("troubleshoot.issues")}</Typography>
            <FAQ questions={questions} />
          </div>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(TroubleshootIssues);
