import firebase from "firebase/app";
import { totalLoss } from "../screens/ResultsScreen";
import { getWholeBodySweatRate, sodiumLossLevel, getWholeBodySodiumRate } from "../common/sweatCalculations";
import "firebase/firestore";
import { WorkoutProps } from './types'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  });
}

const db = firebase.firestore();
const getServerTimestamp = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const addWorkout = async (props: WorkoutProps) => {
  const today = new Date();
  const month = today.toLocaleString("default", { month: "long" });
  const day = today.toLocaleString("default", {
    day: "numeric",
  });
  const displayDate = `${month}-${day}`;

  const workoutRef = db
    .collection("Entries")
    .doc(displayDate)
    .collection("Profiles");

  let sweatRateParams = {
    activity: props.activity || "RUNNING",
    duration: props.duration,
    gender: props.gender,
    facilityType: props.facilityType,
    language: "en",
    sweatRate: totalLoss(props.fluid, props.duration),
    temperature: props.temperature,
    version: 2,
    weight: props.weight,
  };

  const wbsr = getWholeBodySweatRate(sweatRateParams);
  const wholeBodySodiumRate = getWholeBodySodiumRate(
    parseFloat(props.sodium || "0"),
    parseFloat(props.fluid || "0")
  )

  return workoutRef
    .add({
      ...props,
      timestamp: getServerTimestamp(),
      sodium_loss_descriptor: sodiumLossLevel(parseInt(props.age), wholeBodySodiumRate),
      sweat_loss_classification: wbsr.classification,
      // sodiumLossLevel: sodiumLossLevel(
      //   parseInt(props.age),
      //   parseFloat(props.sodium)
      // ),
      whole_body_sweat_rate: wbsr.rate,
      whole_body_sodium_rate: wholeBodySodiumRate,
    })
    .then((docRef) => {
      const date = displayDate;
      const id = docRef.id;
      let newData = {
        id,
        date,
      };
      docRef.update(newData);
      return { id, date };
    })
    .catch((err) => {
      console.warn(err);
    });
};

export const getWorkout = async (date: string, id: string) => {
  const workoutRef = db
    .collection("Entries")
    .doc(date)
    .collection("Profiles")
    .doc(id);
  const doc = await workoutRef.get();
  if (!doc.exists) {
  } else {
    return doc.data();
  }
};

export const getWorkouts = async (date: string) => {
  const workoutRef = db.collection("Entries").doc(date).collection("Profiles");

  const data: any[] = [];
  const stream: any = await workoutRef.get();

  stream.docs.forEach((doc: any) => {
    data.push(doc.data());
  });

  return data;
};
