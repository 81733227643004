import React, { useRef } from "react";
import S3 from "react-aws-s3";
import { v4 as uuidv4 } from "uuid";

export default function UploadFileButton({
  onClick,
  children,
  classes,
  className,
  onChange,
  setPatchPhoto,
  accept,
  capture,
  type,
}: any): JSX.Element {
  const takePhotoInput: any = useRef<HTMLInputElement>(null);
  function handlePhoto(event: any) {
    event.preventDefault();
    let file;
    let newFileName = "newfile.jpg";
    const isPatchPhoto = type === "PATCH";
    takePhotoInput.current?.focus();
    if (takePhotoInput.current !== null) {
      file = takePhotoInput?.current.files[0];
      newFileName = uuidv4();
      // takePhotoInput?.current.files[0].name;
    }

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: type === "PATCH" ? "patches" : "profilePics",
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      s3Url: "https://s3.amazonaws.com/www.gxsweatpatch.com",
    };

    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName).then((data: any) => {
      if (data.status === 204) {
        if (isPatchPhoto) {
          setPatchPhoto(data.location);
        } else {
          onChange(data.location);
        }
      } else {
        console.warn("fail");
      }
    });

    if (isPatchPhoto) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => onChange(reader.result);
    }
  }

  return (
    <>
      <div
        className={className}
        onClick={(e) => {
          if (takePhotoInput === null || takePhotoInput.current === null)
            return;
          takePhotoInput.current.click();
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {children}
      </div>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        capture={capture}
        ref={takePhotoInput}
        onChange={handlePhoto}
      />
    </>
  );
}
