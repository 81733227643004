import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import SweatPatch from "../images/SweatPatch2021.png";

import { colors } from "../theme";

const useStylesGatorade = makeStyles(() => ({
  root: {
    position: "fixed",
    bottom: 260,
    left: 24,
    transform: "rotate(0deg)",
  },
  bottom: {
    position: "absolute",
    color: colors.white,
    width: "80px",
    height: "80px",
    background: `conic-gradient(from 180deg at 50% 50%, ${colors.highFluidRate} -2.86deg, ${colors.highSodium} 175.89deg, ${colors.lowFluidRate} 180.13deg, ${colors.highFluidRate} 357.14deg, ${colors.highSodium} 535.89deg)`,
    borderRadius: "80px",
  },
  top: {
    animationDuration: "1500ms",
    position: "absolute",
    left: 0,
    transform: "rotate(-90deg)",
  },
  circle: {
    stroke: "#DEDEDE",
    strokeLinecap: "round",
  },
  disc: {
    position: "absolute",
    background: colors.white,
    width: "72px",
    height: "72px",
    top: 4,
    left: 4,
    borderRadius: "72px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface GxProps {
  progress: number;
  props?: CircularProgressProps;
}

export default function GxProgress({ progress = 0, props }: GxProps) {
  const classes = useStylesGatorade();

  return (
    <div className={classes.root}>
      <div className={classes.bottom} />
      <CircularProgress
        variant="determinate"
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={80}
        thickness={2}
        {...props}
        value={-1 * (100 - progress)}
      />
      <div className={classes.disc}>
        <img src={SweatPatch} alt="Gx Sweat Patch" width="40" height="40" />
      </div>
    </div>
  );
}
