import React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "../images/icons/left.svg";
import HelpButton from "./HelpButton";
import BackButton from "./BackButton";

const styles = createStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
  },
  icon: {
    padding: 16,
    display: "flex",
    alignItems: "center",
  },
  helpButton: {
    width: 24,
    height: 24,
  },
});

interface AppBarProps {
  classes: any;
  backTo: string;
  onBack(): void;
  onClose(): void;
  showHelp: boolean;
}

function AppBar({ classes, onBack, backTo, onClose, showHelp = false }: any) {
  return (
    <div className={classes.root}>
      <div>
        {onBack && <BackIcon onClick={onBack} className={classes.icon} />}
        {backTo && (
          <Link to={backTo}>
            <BackButton />
          </Link>
        )}
        {onClose && <BackButton onClick={onClose} className={classes.icon} />}
      </div>
      <div>{showHelp && <HelpButton className={classes.helpButton} />}</div>
    </div>
  );
}

export default withStyles(styles)(AppBar);
