import React from "react";
import UploadFileButton from "./UploadFileButton";

export default function TakePhotoButton({
  onClick,
  children,
  onPhoto,
  setPatchPhoto,
  className,
  type,
}: any): JSX.Element {
  return (
    <UploadFileButton
      onClick={onClick}
      accept="image/*"
      capture={true}
      onChange={onPhoto}
      setPatchPhoto={setPatchPhoto}
      className={className}
      type={type}
    >
      {children}
    </UploadFileButton>
  );
}
