import { useEffect, useState } from 'react'

export interface GeolocationPositionError {
  code: number;
  message: string;
}

export interface CoordinateProps {
  coords: any;
  timestamp: any;
}

export interface GeoProps {
  accuracy: any;
  altitude: any;
  altitudeAccuracy: any;
  heading: any;
  latitude: any;
  longitude: any;
  speed: any;
  timestamp: any;
  error: any;
}

type ErrorType = GeolocationPositionError | null;

const useGeolocation = ({enableHighAccuracy = false, maximumAge = 100000, timeout = 1000000} = {}, callback: any) => {

  const [coordinates, setCoordinates] = useState({
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: null,
    error: null,
  })

  useEffect(() => {
    let didCancel: boolean
    const updateCoordinates = ({coords = {}, timestamp}: CoordinateProps) => {
      const {
        accuracy,
        altitude,
        altitudeAccuracy,
        heading,
        latitude,
        longitude,
        speed
      } = coords
      if (!didCancel) {
        setCoordinates({
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp,
          error: null
        })
        if (callback instanceof Function) {
          callback({
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            latitude,
            longitude,
            speed,
            timestamp,
            error: null
          })
        }
      }
    }

    const setError = (error: any) => {
      if (!didCancel) {
        setCoordinates({
          accuracy: null,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: null,
          longitude: null,
          speed: null,
          timestamp: null,
          error
        })
      }
    }

    let watchId: any
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError)
      watchId = navigator.geolocation.watchPosition(
        updateCoordinates,
        setError,
        {enableHighAccuracy, maximumAge, timeout}
      )
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
      didCancel = true
    }
  }, [callback, enableHighAccuracy, maximumAge, timeout])

  return coordinates
}

export default useGeolocation