import React from "react";
import {
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ReactComponent as Check } from "../images/icons/check-filled.svg";
import { colors } from "../theme";

const styles = createStyles({
  suggestion: {
    color: colors.black,
    fontSize: 15,
    lineHeight: "22px",
    fontFamily: "Helvetica Now",
  }
});

function PicSuggestion({
  classes,
  tip,
}: any) {
  return (
    <div className={classes.suggestion}>
      <span style={{ position: 'relative', top: 4 }}><Check /></span>
      <span style={{ marginLeft: 8 }}>
        {tip}
      </span>
    </div>
  );
}

export default withStyles(styles)(PicSuggestion);
