import React from "react";
import {
  Typography,
  Button,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AppBar from "../../components/AppBar";

import { colors } from "../../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  content: {
    margin: 24,
    marginTop: 0,
  },
  row: {
    display: "flex",
    gap: 16,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  ctas: {
    marginTop: 24,
    marginBottom: 48,
    width: "100%",
    textAlign: "center",
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
});

function Watchouts({ classes }: any) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AppBar backTo="/how-to-use/sweat-patch-overview" />

      <div className={classes.content}>
        <Typography variant="h3">{t("watchouts.title")}</Typography>

        <ul style={{ paddingLeft: 24, paddingBottom: 30 }}>
          <li>
            <Typography variant="body1">
              {t("watchouts.watchoutPoint1")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {t("watchouts.watchoutPoint2")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {t("watchouts.watchoutPoint3")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {t("watchouts.watchoutPoint4")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {t("watchouts.watchoutPoint5")}
            </Typography>
          </li>
        </ul>

        <div className={classes.ctas}>
          <Button
            component={Link}
            to="/how-to-use/dont-forget"
            className={classes.cta}
          >
            {t("watchouts.cta")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Watchouts);
