import React from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import { ReactComponent as GxLogo } from "../images/gx.svg";

import { colors } from "../theme";

const useStylesGatorade = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: colors.white,
  },
  top: {
    animationDuration: "1000ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    stroke: "linear-gradient(#F00,#00F)",
    strokeLinecap: "round",
  },
  icon: {
    position: "absolute",
    top: "25%",
    left: "8%",
  },
}));

export default function GatoradeCircularProgress(props: CircularProgressProps) {
  const classes = useStylesGatorade();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={150}
        thickness={1}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={150}
        thickness={2}
        {...props}
      />
      <div className={classes.icon}>
        <GxLogo />
      </div>
    </div>
  );
}
