import React from "react";
import {
  Typography,
  Button,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AppBar from "../../components/AppBar";
import TroubleshootIssues from "../../components/TroubleshootIssues";

import { colors } from "../../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  content: {
    margin: 24,
    marginTop: 0,
    "& p": {
      marginTop: 10,
    },
  },
  ctas: {
    marginTop: 24,
    marginBottom: 48,
    width: "100%",
    textAlign: "center",
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
});

function DontForget({ classes }: any) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AppBar backTo="/how-to-use/watchouts" />

      <div className={classes.content}>
        <Typography variant="h3">
          {t("dontForget.title")} <span>⏲</span>
        </Typography>

        <Typography variant="body1">
          <strong>{t("dontForget.descriptionTitle")}</strong>
          <br />
          {t("dontForget.description")}
        </Typography>

        <Typography variant="body2" style={{ marginTop: 200 }}>
          <TroubleshootIssues />
        </Typography>

        <div className={classes.ctas}>
          <Button
            component={Link}
            to="/before-you-start"
            className={classes.cta}
          >
            {t("dontForget.scanAPatch")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(DontForget);
