import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import honeybadger from './honeybadger'
import SplashScreen from "./screens/SplashScreen";
import ActivityScreen from "./screens/ActivityScreen";
import AthleteScreen from "./screens/AthleteScreen";
import ConfirmSweatTestDetails from "./screens/ConfirmSweatTestDetails";
import ConfirmationScreen from "./screens/ConfirmationScreen";
import BeforeYouStartScreen from "./screens/BeforeYouStartScreen";
import BeforeYouStartScreen2 from "./screens/BeforeYouStartScreen-2";
import BeforeYouStartScreen3 from "./screens/BeforeYouStartScreen-3";
import BeforeYouStartScreen4 from "./screens/BeforeYouStartScreen-4";
import HowToUse from "./screens/IFU/HowToUse";
import DontForget from "./screens/IFU/DontForget";
import Watchouts from "./screens/IFU/Watchouts";
import ResultsScreen from "./screens/ResultsScreen";
import PhotoSuccessScreen from "./screens/PhotoSuccessScreen";
import RetryPhotoScreen from "./screens/RetryScreen";
import FailureScreen from "./screens/FailureScreen";
import TermsScreen from "./screens/TermsScreen";
import activites from "./common/activities";

import {
  HashRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import { addWorkout, sendEmail } from "./api";
import ErrorBoundary from "./components/ErrorBoundary";

function SweatTestRouter() {
  // Patch
  const [patchPhoto, setPatchPhoto] = useState("");
  const [fluid, setFluid] = useState("");
  const [sodium, setSodium] = useState("");

  // Activity
  const [activity, setActivity] = useState("");
  const [duration, setDuration] = useState("");
  const [fluidConsumed, setFluidConsumed] = useState("");
  const [facilityType, setFacilityType] = useState("INDOOR");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [temperature, setTemperature] = useState("");
  const [humidity, setHumidity] = useState("");
  const [location, setLocation] = useState("");

  // Athlete
  const [name, setName] = useState("");
  const firstName = name ? name.split(" ")[0] : "";
  const lastName = name ? name.split(" ")[1] : "";
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [weight, setWeight] = useState("");
  const [phone, setPhone] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [email, setEmail] = useState("");

  interface FireBaseData {
    date: string;
    id: string;
    resp?: any;
    error?: string;
  }

  const [contactViaWhatsApp, setContactViaWhatsApp] = useState(false);
  const [apiData, setApiData] = useState<FireBaseData | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const { i18n } = useTranslation();

  const handleSubmit = async (history: any) => {
    setLoading(true)
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1];
    const language = i18n.language;
    try {
      addWorkout({
        activity: activites[parseInt(activity)],
        age,
        country,
        duration,
        email,
        facilityType,
        fluid,
        gender,
        humidity,
        location,
        name,
        firstName,
        lastName,
        language,
        patchPhoto,
        phone,
        postalCode,
        profilePicture,
        sodium,
        temperature,
        weight: parseInt(weight),
        fluidConsumed: parseFloat(fluidConsumed)
      }).then((resp: any) => {
        console.log("sent to firebase", resp);
        if (sodium && fluid && resp.data && resp.data.CreateSweatTest && resp.data.CreateSweatTest.sweat_test) {
          const { sweat_test } = resp.data.CreateSweatTest
          setApiData({ date: sweat_test.created_at, id: sweat_test.id });

          sendEmail({
            name: name.split(" ")[0],
            email: email,
            lang: language,
            date: sweat_test.created_at,
            id: sweat_test.id,
          });
        } else if (resp.data && resp.data.CreateSweatTest && resp.data.CreateSweatTest.success) {
          setApiData({ date: "MANUAL", id: "" });
        } else {
          setLoading(false)
          setErrorMessage("We were unable to send your sweat test in for processing. Please try again.");
          setApiData(null);
        }
      });
    } catch (e) {
      console.warn("err", e);
      honeybadger.notify(e)
      setLoading(false)
      setErrorMessage("An Error Occurred");
      setApiData(null);
    }
  };

  // All Done!
  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <Route path="/before-you-start" component={BeforeYouStartScreen} />
          <Route path="/before-you-start-2" component={BeforeYouStartScreen2} />
          <Route path="/before-you-start-3" component={BeforeYouStartScreen3} />
          <Route path="/before-you-start-4">
            <BeforeYouStartScreen4
              onPhoto={setPatchPhoto}
              patchPhoto={patchPhoto}
              setPatchPhoto={setPatchPhoto}
              setSodium={setSodium}
              setFluid={setFluid}
            />
          </Route>
          <Route path="/retry-photo">
            <RetryPhotoScreen
              onPhoto={setPatchPhoto}
              patchPhoto={patchPhoto}
              setPatchPhoto={setPatchPhoto}
              setSodium={setSodium}
              setFluid={setFluid}
            />
          </Route>
          <Route path="/activity">
            <ActivityScreen
              activity={activity}
              setActivity={setActivity}
              duration={duration}
              setDuration={setDuration}
              facilityType={facilityType}
              setFacilityType={setFacilityType}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              country={country}
              setCountry={setCountry}
              temperature={temperature}
              setTemperature={setTemperature}
              humidity={humidity}
              setHumidity={setHumidity}
              location={location}
              setLocation={setLocation}
              fluidConsumed={fluidConsumed}
              setFluidConsumed={setFluidConsumed}
            />
          </Route>
          <Route path="/athlete">
            <AthleteScreen
              name={name}
              setName={setName}
              gender={gender}
              setGender={setGender}
              age={age}
              setAge={setAge}
              weight={weight}
              setWeight={setWeight}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              contactViaWhatsApp={contactViaWhatsApp}
              setContactViaWhatsApp={setContactViaWhatsApp}
              handleSubmit={() => handleSubmit(history)}
              country={country}
            />
          </Route>
          <Route path="/confirm-details">
            <ConfirmSweatTestDetails
              firstName={firstName}
              lastName={lastName}
              activity={activity}
              duration={duration}
              name={name}
              postalCode={postalCode}
              facilityType={facilityType}
              gender={gender}
              country={country}
              weight={weight}
              age={age}
              phone={phone}
              patchPhoto={patchPhoto}
              profilePicture={profilePicture}
              contactViaWhatsApp={contactViaWhatsApp}
              email={email}
              sodium={sodium}
              fluid={fluid}
              handleSubmit={handleSubmit}
              apiData={apiData}
              errorMessage={errorMessage}
              loading={loading}
            />
          </Route>
          <Route path="/confirmation">
            <ConfirmationScreen firstName={firstName} />
          </Route>
          <Route path="/thank-you">
            <FailureScreen firstName={firstName} />
          </Route>
          <Route path="/results/:date/:id/:debug">
            <ResultsScreen />
          </Route>
          <Route path="/results/:date/:id">
            <ResultsScreen />
          </Route>

          <Route path="/how-to-use/sweat-patch-overview" component={HowToUse} />
          <Route path="/how-to-use/watchouts" component={Watchouts} />
          <Route path="/how-to-use/dont-forget" component={DontForget} />
          <Route path="/photo-success" component={PhotoSuccessScreen} />
          <Route path="/terms" component={TermsScreen} />
          <Route path="" component={SplashScreen} />
        </Switch>
      </ErrorBoundary>
    </Router>
  );
}

export default SweatTestRouter;
