import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core";
import Div100vh from "react-div-100vh";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import GxProgress from "../components/GxProgress";
import { motion, AnimatePresence } from "framer-motion";

const styles = createStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    margin: 24,
    textAlign: "left",
  },
});

function ProcessingScreen({ classes }: any) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const [steps, setSteps] = useState<number>(0);

  const getText = (step: number) => {
    if (step > 2 && step < 10) {
      return t("processing.step1");
    }
    if (step > 12 && step < 20) {
      return t("processing.step2");
    }
    if (step > 22 && step < 30) {
      return t("processing.step3");
    }
    if (step > 32) {
      return t("processing.step4");
    }
  };

  const getProgress = (step: number) => step * 2.5;

  useEffect(() => {
    setTimeout(() => {
      if (steps < 40) {
        setSteps(steps + 1);
      }
    }, 500);
  }, [steps, history]);

  return (
    <Div100vh className={classes.root}>
      <AnimatePresence initial={false}>
        <motion.div
          layout
          key={getText(steps)}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <Typography variant="h2">{getText(steps)}</Typography>
        </motion.div>
      </AnimatePresence>

      <GxProgress progress={0 || getProgress(steps)} />
    </Div100vh>
  );
}

export default withStyles(styles)(ProcessingScreen);
