import React from "react";
import { withStyles, createStyles } from "@material-ui/core";
// import Button from "@material-ui/core/Button";
// import { colors } from "../theme";
import { ReactComponent as BackIcon } from "../images/icons/back-arrow.svg";

const styles = createStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: "100%",
    borderColor: "rgba(0,0,0,0.25)",
    borderWidth: 1,
    background: "transparent",
    "&:active": {
      background: "rgba(0,0,0,0.25)",
    },
  },
});

function BackButton({ classes, className }: any) {
  return (
    <button className={`${classes.root} ${className}`}>
      <BackIcon />
    </button>
  );
}

export default withStyles(styles)(BackButton);
