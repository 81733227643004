import React from "react";

interface ColorProps {
  width?: number;
  colorArray?: string;
  startPercent?: number;
  endPercent?: number;
}

function GradientSlider({
  width,
  colorArray = "#F00, #00F",
  startPercent,
  endPercent,
}: ColorProps): JSX.Element {
  let numericWidth = width || 400;
  return (
    <div
      style={{
        position: "relative",
        padding: "5px",
        borderRadius: "999px",
        width,
        backgroundImage: `linear-gradient(to right, ${colorArray})`,
      }}
    >
      {startPercent && endPercent && (
        <div
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.2)",
            padding: 4,
            borderRadius: 4,
            top: 1,
            left: startPercent + "%",
            width: ((endPercent - startPercent) / 100) * numericWidth + "px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              padding: 4,
              borderRadius: 4,
              background: "#000",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              padding: 4,
              borderRadius: 4,
              background: "#000",
            }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default GradientSlider;
