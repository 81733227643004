import dataUriToBlob from "../common/dataUriToBlob";

const patchHost = process.env["REACT_APP_PATCH_API_HOST"];
// const recommendationsHost = process.env["REACT_APP_RECOMMENDATIONS_API_HOST"];


export function patchAPI(image: string) {
  const data = new FormData();
  data.append("image", dataUriToBlob(image));

  return fetch(`${patchHost}/graphql/public/patch_channels`, {
    method: "POST",
    body: data,
    headers: {
      "Keep-Alive": "timeout=15, max=100",
    },
  })
    .then((response) => {
      return response.text();
    })
    .then(JSON.parse);
}
