import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useGeolocation, {
  CoordinateProps,
  GeolocationPositionError,
} from "../hooks/useGeolocation";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  Typography,
  Select,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { colors } from "../theme";
import AppBar from "../components/AppBar";
import activities from "../common/activities";
import countries from "../common/countries";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 24,
      paddingTop: 0,
      display: "flex",
      flexDirection: "column",
      columnGap: 24,
    },
    durationSplit: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      color: colors.grey,
    },
    durationField: {
      flex: 1,
    },
    cta: {
      marginTop: 24,
      textAlign: "center",
      marginBottom: 64,
    },
    ctaButton: {
      width: "100%",
      background: colors.black,
      color: colors.white,
    },
    disabledButton: {
      width: "100%",
      background: colors.white,
    },
    formControl: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(4),
    },
  })
);

function ActivityScreen({
  activity: selectedActivity,
  setActivity,
  duration,
  setDuration,
  fluidConsumed,
  setFluidConsumed,
  facilityType = "INDOOR",
  setFacilityType,
  country,
  setCountry,
  postalCode,
  setPostalCode,
  confirmActivity,
  temperature = 21.3,
  setTemperature,
  humidity = 0,
  setHumidity,
  location,
  setLocation,
}: any) {
  const classes = useStyles();

  const [postalCodeError, setPostalCodeError] = useState<string | null>();
  const [durationHr, setDurationHr] = useState<string | null>();
  const [durationMin, setDurationMin] = useState<string | null>();
  const [loadedWeather, setLoadedWeather] = useState<boolean>(false);
  const [locale, setLocale] = useState<any>({ name: "", state: "" });

  const fetchWeather = async (lat: string, lon: string) => {
    const apiKey = process.env.REACT_APP_OPEN_WEATHER_API_KEY;
    const resp = await fetch(
      `https://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&limit=5&appid=${apiKey}`
    );
    const city = await resp.json();

    if (city[0]) {
      await setLocale(city[0]);
      setCountry(city[0].country);
      const forecastResp = await fetch(
        `https://api.openweathermap.org/data/2.5/forecast?q=${city[0].name}&APPID=${apiKey}&units=metric`
      );
      const weather = await forecastResp.json();
      if (weather) {
        console.log("<<", weather);
        setTemperature(weather.list[0].main.temp);
        setHumidity(weather.list[0].main.humidity);
        await setLocale({
          name: city[0].name,
          country: city[0].country,
          state: city[0].state,
          temp: weather.list[0].main.temp,
          humidity: weather.list[0].main.humidity,
        });
        setLocation(city[0]);
      }
    }
  };

  const fetchLatLngFromPostalCode = async (
    postalCode: string,
    countryCode: string
  ) => {
    console.log("fetch from postal code>", postalCode, countryCode);
    try {
      const gMapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const resp = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${gMapApiKey}&components=postal_code:${postalCode}|country:${countryCode}`
      );
      // const resp = await fetch(
      //   `https://thezipcodes.com/api/v1/search?zipCode=${postalCode}&countryCode=${countryCode}&apiKey=1b035c75c5b956f8f9f03cc365cfca69`,
      //   { mode: "no-cors" }
      // )
      const data = await resp.json();
      if (data.results.length) {
        return data.results[0].geometry?.location;
      } else {
        return { lat: 0, lng: 0 };
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (country && isValidPostalCode()) {
      fetchLatLngFromPostalCode(postalCode, country)
        .then((resp) => {
          console.log(">", resp);
          if (resp.lat) {
            fetchWeather(resp.lat, resp.lng);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    } else {
      console.warn("invalid >", postalCode, country);
    }
  }, [country, postalCode]);

  let geoLocation = useGeolocation(
    {
      enableHighAccuracy: false,
      maximumAge: 100000,
      timeout: 1000000,
    },
    useMemo(() => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (pos: CoordinateProps) => {
          return pos;
        };
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (err: GeolocationPositionError) => {
          return err;
        };
      }
    }, [])
  );

  useEffect(() => {
    console.log("<<", loadedWeather, facilityType);
    if (location === "") {
      fetchLatLngFromPostalCode(postalCode, country);
    }
    if (
      geoLocation !== null &&
      geoLocation.latitude !== null &&
      geoLocation.longitude !== null &&
      loadedWeather &&
      facilityType === "OUTDOOR"
    ) {
      const lat = parseFloat(geoLocation.latitude || "0").toFixed(4);
      const lon = parseFloat(geoLocation.longitude || "0").toFixed(4);

      fetchWeather(lat, lon);
    } else if (facilityType === "INDOOR") {
      setTemperature(21.3);
    } else if (locale.temp) {
      setTemperature(locale.temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedWeather, facilityType]);

  useEffect(() => {
    console.log("geoLoc:", geoLocation);
    if (geoLocation) {
      const loc = geoLocation.accuracy;
      if (loc && parseInt(loc || "0") >= 10) {
        setLoadedWeather(true);
      }
    }
  }, [geoLocation]);

  useEffect(() => {
    const hrs = durationHr ? parseInt(durationHr) : 0;
    const min = durationMin ? parseInt(durationMin) : 0;
    setDuration(hrs * 60 + min);
  }, [durationHr, durationMin, setDuration]);

  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function valid() {
    return (
      selectedActivity !== "" &&
      duration &&
      duration >= 30 &&
      ((geoLocation !== null &&
        geoLocation?.accuracy !== null &&
        parseFloat(geoLocation.accuracy || "0") >= 10) ||
        (postalCode && isValidPostalCode())) &&
      facilityType
    );
  }

  function isValidPostalCode() {
    return postalCode && postalCode.length >= 5 && postalCode.length <= 8;
  }

  function checkValidPostalCode() {
    if (isValidPostalCode()) {
      setPostalCodeError(null);
    } else {
      setPostalCodeError("Please enter a valid postal code");
    }
  }

  const durationProp = {
    pattern: "\\d*",
    min: 0,
    max: 59,
  };

  const durationHrProp = {
    pattern: "\\d*",
    min: 0,
    max: 9,
  };

  return (
    <div>
      <AppBar backTo="/how-to-use/sweat-patch-overview" />
      <div className={classes.root}>
        <Typography variant="h2" gutterBottom>
          {t("activity.title")}
        </Typography>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="activity-selector">
            {t("activity.activity")}
          </InputLabel>
          <Select
            native
            value={selectedActivity}
            onChange={(e) => setActivity(e.target.value)}
            inputProps={{
              name: "activity",
              id: "activity-selector",
            }}
          >
            <option value=""></option>
            {activities.map((activity, idx) => (
              <option key={activity} value={idx}>
                {t(`activities.${[activity.toLowerCase()]}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        <Typography variant="body1" gutterBottom>
          {t("activity.howLong")}
        </Typography>
        <div className={classes.formControl}>
          <InputLabel>{t("activity.duration")}</InputLabel>
          <div className={classes.durationSplit}>
            <TextField
              name="durationHr"
              className={classes.durationField}
              value={durationHr}
              onChange={(e) => setDurationHr(e.target.value.substring(0, 1))}
              type="number"
              placeholder={t("activity.hour")}
              inputProps={durationHrProp}
            />
            <TextField
              name="durationMin"
              className={classes.durationField}
              value={durationMin}
              onChange={(e) => setDurationMin(e.target.value)}
              type="number"
              placeholder={t("activity.minutes")}
              inputProps={durationProp}
            />
          </div>
        </div>
        <FormControl className={classes.formControl}>
          <FormLabel>{t("activity.fluidConsumed")}</FormLabel>
          <TextField
            name="fluidConsumed"
            value={fluidConsumed}
            onChange={(e) => setFluidConsumed(e.target.value)}
            type="number"
            placeholder={t("results.ml")}
          />
        </FormControl>

        <Typography variant="body1" gutterBottom>
          {t("activity.whereAbouts")}
        </Typography>
        <FormLabel>{t("activity.whereAboutsDisclaimer")}</FormLabel>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="country-selector">
            {t("activity.country")}
          </InputLabel>
          <Select
            native
            value={country}
            disabled={!!locale.country}
            onChange={(e: any) => {
              if (e.target.value) {
                setCountry(e.target.value);
              }
            }}
            inputProps={{
              name: "country",
              id: "country-selector",
            }}
          >
            <option value=""></option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {t("countries." + country || "")}
              </option>
            ))}
          </Select>
        </FormControl>
        {geoLocation && geoLocation.latitude && geoLocation.longitude ? (
          <input type="hidden" name="temp" value={temperature} />
        ) : (
          <>
            <TextField
              inputProps={{
                pattern: "\\d*",
              }}
              type="input"
              value={postalCode}
              error={!!postalCodeError}
              helperText={postalCodeError}
              onFocus={() => setPostalCodeError(null)}
              onBlur={() => checkValidPostalCode()}
              onChange={(e) => setPostalCode(e.target.value.substring(0, 8))}
              placeholder={t("activity.postalCode")}
            />
            { country === "AR" && (
              <Typography variant="body1" gutterBottom>
                {t("activity.argentinaPostalWarning")}
              </Typography>
            )}
          </>
        )}
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="facility-selector">
            {t("activity.indoor")} / {t("activity.outdoor")}
          </InputLabel>
          <Select
            native
            value={facilityType}
            onChange={(e) => setFacilityType(e.target.value)}
            inputProps={{
              name: "age",
              id: "facility-selector",
            }}
          >
            <option value="INDOOR">{t("activity.indoor")}</option>
            <option value="OUTDOOR">{t("activity.outdoor")}</option>
          </Select>
        </FormControl>

        {/* <pre>
          found location: {location !== ""?"yes": "no"}
          <br/>
          temp: {temperature}
          <br/>
          humid: {humidity}
        </pre> */}

        <div className={classes.cta}>
          <Button
            onClick={confirmActivity}
            className={valid() ? classes.ctaButton : classes.disabledButton}
            disabled={!valid()}
            component={Link}
            to="athlete"
          >
            {t("activity.cta")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ActivityScreen;
