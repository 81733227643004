import React from "react";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Button,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AppBar from "../../components/AppBar";
import Divider from "../../components/Divider";

import TempIcon from "./temp_icon.png";
import TimeIcon from "./time_icon.png";

import { colors } from "../../theme";

const styles = createStyles({
  content: {
    margin: 24,
    marginTop: 0,
    "& p": {
      marginTop: 10,
    },
  },
  row: {
    display: "flex",
    gap: 16,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  ctas: {
    marginTop: 24,
    marginBottom: 48,
    width: "100%",
    textAlign: "center",
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
  list: {
    paddingLeft: 24,
  },
});

function SweatPatchOverview({ classes }: any) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AppBar backTo="/" />

      <div className={classes.content}>
        <Typography variant="h2">{t("howToUse.title")}</Typography>

        <Typography variant="body1">{t("howToUse.description")}</Typography>

        <Divider />

        <Typography variant="h2" color="primary">
          {t("howToUse.prepareTitle")}
        </Typography>

        <ul className={classes.list}>
          <li>
            <Typography variant="body1">
              {t("howToUse.preparePoint1")}
            </Typography>
          </li>
        </ul>

        <div className={classes.row}>
          <div className={classes.col}>
            <img src={TempIcon} alt="Temperature" width="64" height="64" />
            <Typography variant="h3">8 - 35&deg;C</Typography>
            <Typography variant="body2" color="textSecondary">
              {t("howToUse.validTemperature")}
            </Typography>
          </div>
          <div className={classes.col}>
            <img src={TimeIcon} alt="Time" width="64" height="64" />
            <Typography variant="h3">{t("howToUse.durationRange")}</Typography>
            <Typography variant="body2" color="textSecondary">
              {t("howToUse.validDuration")}
            </Typography>
          </div>
        </div>

        <Divider />

        <Typography variant="h2" color="primary">
          {t("howToUse.applyTitle")}
        </Typography>

        <ul className={classes.list}>
          <li>
            <Typography variant="body1">{t("howToUse.applyPoint1")}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t("howToUse.applyPoint2")}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t("howToUse.applyPoint3")}</Typography>
          </li>
        </ul>

        <Divider />

        <Typography variant="h2" color="primary">
          {t("howToUse.workoutTitle")}
        </Typography>

        <ul className={classes.list}>
          <li>
            <Typography variant="body1">
              {t("howToUse.workoutPoint1")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {t("howToUse.workoutPoint2")}
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              {t("howToUse.workoutPoint3")}
            </Typography>
          </li>
        </ul>

        <Divider />

        <Typography variant="h2" color="primary">
          {t("howToUse.scanTitle")}
        </Typography>
        <ul className={classes.list}>
          <li>
            <Typography variant="body1">{t("howToUse.scanPoint1")}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t("howToUse.scanPoint2")}</Typography>
          </li>
          <li>
            <Typography variant="body1">{t("howToUse.scanPoint3")}</Typography>
          </li>
        </ul>

        <div className={classes.ctas}>
          <Button
            component={Link}
            to="/how-to-use/watchouts"
            className={classes.cta}
          >
            {t("howToUse.cta")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SweatPatchOverview);
