import React from "react";
import { createStyles, withStyles, Typography } from "@material-ui/core";

import HelpButton from "../components/HelpButton";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  header: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    marginBottom: 12,
    marginTop: -20,
  },
  helpBtn: {
    marginTop: 20,
  },
});

function PatchCaptureHeader({ classes }: any) {
  const { t } = useTranslation();
  return (
    <div className={classes.header}>
      <Typography variant="overline">{t("capture.patchCapture")}</Typography>
      <div className={classes.helpBtn}>
        <HelpButton />
      </div>
    </div>
  );
}

export default withStyles(styles)(PatchCaptureHeader);
