import React from "react";
import { withStyles, createStyles } from "@material-ui/core";
import { colors } from "../theme";

const styles = createStyles({
  root: {
    width: "100%",
    height: 48,
    borderRadius: 24,
    background: colors.black,
    color: colors.white,
    fontSize: 16,
    transitionDuration: '300ms',
    "&:active": {
      background: colors.darkishGrey,
    },
    "&:disabled": {
      background: colors.white,
      color: colors.darkishGrey,
      borderColor: colors.grey,
    },
  },
});

function RegButton({ classes, className, disabled, onClick, children }: any) {
  return (
    <button
      disabled={disabled}
      className={`${classes.root} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default withStyles(styles)(RegButton);
