import React from "react";
import {
  createStyles,
  withStyles,

} from "@material-ui/core";
import { ReactComponent as ErrorX } from "../images/icons/small-x.svg";
import { colors } from "../theme";

const styles = createStyles({
  errorCaption: {
    color: colors.errorRed,
    fontSize: 15,
    lineHeight: "22px",
    fontFamily: "Helvetica Now",
  }
});

function PicError({
  classes,
  error,
}: any) {
  return (
    <div className={classes.errorCaption}>
      <ErrorX />
      <span style={{ marginLeft: 8 }}>
        {error}
      </span>
    </div>
  );
}

export default withStyles(styles)(PicError);
