import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Typography, createStyles, withStyles } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../images/icons/close.svg";

const styles = createStyles({
  icon: {
    width: 24,
    height: 24,
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.58)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 11,
  },
  modal: {
    fontSize: 16,
    lineHeight: "20px",
    borderRadius: 8,
    boxShadow: "0,0,4px,rgba(0,0,0,0.5)",
    width: "calc(100% - 56px)",
    boxSizing: "border-box",
    backgroundColor: "rgba(255,255,255,1)",
    textAlign: "right",
    "& a": {
      color: "#000",
    },
  },
  modalContent: {
    textAlign: "left",
    width: "100%",
    boxSizing: "border-box",
    paddingRight: 32,
    paddingLeft: 32,
    paddingBottom: 24,
    "& p": {
      marginBottom: 48,
    },
  },
  closeIcon: {
    padding: 10,
  },
});

function Modal({ classes, open, onOpen, onClose, header, description }: any) {
  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
      ReactGA.modalview("gx-support");
    }
  }, [open]);

  return (
    <div>
      {open && (
        <div className={classes.modalContainer} onClick={() => onClose()}>
          <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => onClose()}
            />
            <div className={classes.modalContent}>
              <Typography variant="h4" gutterBottom>
                {header}
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(Modal);
