import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";
import { Typography } from "@material-ui/core";
import GradientSlider from "../components/GradientSlider";
import { colors } from "../theme";

interface ResultsSpectrumProps {
  lowVal: number;
  highVal: number;
  label: string;
  range: number[];
}

function ResultsSpectrum({
  lowVal,
  highVal,
  label,
  range,
}: ResultsSpectrumProps): JSX.Element {
  const { t } = useTranslation();
  const colorStops = "#8EE165, #BDD84B, #FFFB00, #FFCC26, #FE764F";

  const [count, setCount] = useState<number | undefined>(200);
  const { ref } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width }) => {
      if (width) {
        setCount(width - 48);
      }
    },
  });

  const offset = 5;

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        marginLeft: 48,
        width: "calc(100% - 24px)",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Typography variant="body2">
          {lowVal}&ndash;{highVal}
          {label}
        </Typography>
      </div>

      <GradientSlider
        width={count}
        colorArray={
          label === "mg"
            ? [colors.lowSodium, colors.highSodium].toString()
            : colorStops
        }
        startPercent={((lowVal / range[1]) * 100 - offset) % 100 }
        endPercent={((highVal / range[1]) * 100 - offset) % 100 }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: 24,
          color: "#696969",
        }}
      >
        <Typography variant="body2">{t("results.low")}</Typography>
        <Typography variant="body2">{t("results.high")}</Typography>
      </div>
    </div>
  );
}

export default ResultsSpectrum;
