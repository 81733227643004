interface EmailParams {
  name: string;
  email: string;
  id: string;
  date: string;
  lang: string;
}

export const sendEmail = ({ name, email, id, date, lang }: EmailParams) => {
  const data = {
    name: name,
    email: email,
    id: id,
    date: date,
    lang: lang,
  };

  return fetch(
    "https://gaouh1utn0.execute-api.us-east-1.amazonaws.com/default/gx-send-sweat-patch-email",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.warn(">", err);
    });
};
