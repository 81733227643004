import React from "react";
import { OutboundLink } from "react-ga";
import {
  Button,
  withStyles,
  createStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { colors } from "../theme";
import { useTranslation } from "react-i18next";

import Div100vh from "react-div-100vh";

const styles = createStyles({
  root: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  content: {
    color: "black",
    "&:p": {
      color: "black",
    },
  },
  cta: {
    marginTop: 24,
    marginBottom: 48,
    width: "100%",
    textAlign: "center",
  },
  ctaButton: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
});

function FailureScreen({ classes, firstName }: any) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Div100vh className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h2" gutterBottom>
          {t("confirmation.thankYou")} {firstName}
          {t("confirmation.forParticipating")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("confirmation.detailsSent")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("confirmation.forMoreFeedback")}
          <OutboundLink
            eventLabel="Email support from confirmation"
            to="mailto:gxsupport@smartdesignworldwide.com"
          >
            {t("confirmation.emailUs")}
          </OutboundLink>
        </Typography>
      </div>
      <div className={classes.cta}>
        <Button component={Link} to="/" className={classes.ctaButton}>
          {t("confirmation.done")}
        </Button>
      </div>
    </Div100vh>
  );
}

export default withStyles(styles)(FailureScreen);
