import React from "react";
import { Avatar, createStyles, withStyles } from "@material-ui/core";
import { ReactComponent as ProfilePictureImg } from "../images/profile-picture.svg";
import { colors } from "../theme";

const styles = createStyles({
  circle: {
    background: "linear-gradient(#FF6B2B, #FF2779, #2E70DA)",
    height: 90,
    width: 90,
    borderRadius: 46,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    height: 86,
    width: 86,
    borderRadius: 44,
    backgroundColor: colors.grey100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 82,
    height: 82,
  },
});

function ProfilePicture({ classes, profilePicture }: any) {
  return (
    <div className={classes.circle}>
      <div className={classes.root}>
        {profilePicture ? (
          <Avatar src={profilePicture} className={classes.image} />
        ) : (
          <ProfilePictureImg />
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(ProfilePicture);
