import React from "react";
import { OutboundLink } from "react-ga";

interface IErrorBoundaryProps {
  fallback?: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: any;
  errorInfo?: any;
}

const DefaultError: React.FC = () => (
  <p>
    Something went wrong. Please{" "}
    <OutboundLink
      eventLabel="Email support from confirmation"
      to="mailto:gxsupport@smartdesignworldwide.com"
    >
      let us know.
    </OutboundLink>
  </p>
);

// This Component must be a class due to the componentDidCatch lifecycle method not implemented in hooks
class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error | null, errorInfo: object) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <DefaultError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
