import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  createStyles,
  withStyles,
  Typography,
} from "@material-ui/core";

import incorrectPhotoExample from "../images/photos/examples/03_Photo-Wrong.png";

import crosshairs from "../images/crosshairs.svg";

import RegButton from "../components/RegButton";
import TakePhotoButton from "../components/TakePhotoButton";
import PicSuggestion from "../components/PicSuggestion";
import { patchAPI } from "../api";
import ProcessingScreen from "./ProcessingScreen";

import { colors } from "../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    lineHeight: "20px",
    letterSpacing: 0,
    paddingBottom: 48,
  },
  canvas: {
    position: "relative",
    marginTop: 24,
    marginBottom: 8,
    height: "100%",
    width: "100%",
  },
  image: {
    width: "100%",
  },
  crosshairs: {
    position: "absolute",
    top: 54,
    left: 68,
    width: "calc(100% - 135px)",
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 24,
    marginBottom: 48,
  },
  footerLink: {
    marginTop: 24,
    color: colors.black,
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
  btn: {
    width: "100%",
  },
});

function RetryScreen({
  onPhoto,
  patchPhoto,
  setPatchPhoto,
  setFluid,
  setSodium,
  classes,
}: any) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false); // temp for demo
  const [failures, setFailures] = useState(0);

  function handlePhoto(photo: string) {
    setProcessing(true);
    onPhoto(photo);
    patchAPI(photo)
      .then((result: any) => {
        const { fluid, sodium } = result;
        setProcessing(false);
        if (fluid && fluid > 0) {
          setFluid(fluid);
          setSodium(sodium);
          history.push("/photo-success");
        } else if (failures >= 2) {
          // third failure
          setFluid(0);
          setSodium(0);
          history.push("/activity");
        }
        setFailures(failures + 1);
      })
      .catch((error) => {
        console.warn(error);
        setFailures(failures + 1);
        setProcessing(false);
        if (failures >= 0) {
          // third failure
          setFluid(0);
          setSodium(0);
          history.push("/activity");
        }
      });
  }

  if (processing) {
    return <ProcessingScreen />;
  }

  return (
    <div className={classes.content}>
      <Typography variant="h3">{t("retry.title")}</Typography>

      <div className={classes.canvas}>
        {patchPhoto ? (
          <img className={classes.image} alt="Your Patch" src={patchPhoto} />
        ) : (
          <img
            className={classes.image}
            alt="Bad Example"
            src={incorrectPhotoExample}
          />
        )}

        <img src={crosshairs} alt="crosshairs" className={classes.crosshairs} />
      </div>

      <PicSuggestion tip={t("retry.tip1")} />
      <PicSuggestion tip={t("retry.tip2")} />
      <PicSuggestion tip={t("retry.tip3")} />

      <div className={classes.footer}>
        <RegButton className={classes.cta}>
          <TakePhotoButton
            onPhoto={handlePhoto}
            setPatchPhoto={setPatchPhoto}
            type="PATCH"
            className={classes.btn}
          >
            {t("retry.retry")}
          </TakePhotoButton>
        </RegButton>
      </div>
    </div>
  );
}

export default withStyles(styles)(RetryScreen);
