import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Typography,
  withStyles,
  createStyles,
} from "@material-ui/core";
import Div100vh from "react-div-100vh";
import { colors } from "../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  root: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 48,
    display: "flex",
    flexDirection: "column",
    color: colors.black,
  },
  ctas: {
    position: "fixed",
    bottom: 160,
    width: "calc(100% - 48px)",
    textAlign: "center",
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
});

function PhotoSuccessScreen({ classes }: any) {
  const { t } = useTranslation();
  // const history = useHistory()

  // React.useEffect(() => {
  //   setTimeout(() => history.push('/activity'), 2000)
  // }, [history])

  return (
    <Div100vh className={classes.root}>
      <Typography variant="h2">{t("photoSuccess.title")}</Typography>

      <div className={classes.ctas}>
        <Button component={Link} to="/activity" className={classes.cta}>
          {t("photoSuccess.cta")}
        </Button>
      </div>
    </Div100vh>
  );
}

export default withStyles(styles)(PhotoSuccessScreen);
