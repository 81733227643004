import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  createStyles,
  withStyles,
  Typography,
} from "@material-ui/core";

import correctPhoto1 from "../images/photos/examples/04_Photo_Correct-1.png";
import correctPhoto2 from "../images/photos/examples/04_Photo_Correct-2.png";
import correctPhoto3 from "../images/photos/examples/04_Photo_Correct-3.png";

import PatchCaptureHeader from "../components/PatchCaptureHeader";
import TakePhotoButton from "../components/TakePhotoButton";
import RegButton from "../components/RegButton";
import { patchAPI } from "../api";
import ProcessingScreen from "./ProcessingScreen";

import { colors } from "../theme";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: 16,
    lineHeight: "20px",
    letterSpacing: 0,
    paddingBottom: 48,
  },
  image: {
    marginTop: 24,
    marginBottom: 8,
    width: 270,
    height: 307,
  },
  footer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 24,
    marginBottom: 48,
  },
  footerLink: {
    marginTop: 24,
    color: colors.black,
  },
  cta: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
  btn: {
    width: "100%",
  },
});

function BeforeYouStartScreen4({
  onPhoto,
  setPatchPhoto,
  setFluid,
  setSodium,
  classes,
}: any) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false); // temp for demo

  function handlePhoto(photo: string) {
    setProcessing(true);
    onPhoto(photo);
    patchAPI(photo)
      .then((result: any) => {
        const { fluid, sodium } = result;
        setProcessing(false);
        if (fluid && fluid > 0) {
          setFluid(fluid);
          setSodium(sodium);
          history.push("/photo-success");
        }

        if (fluid === -1 && sodium === -1) {
          history.push("/retry-photo");
        }
      })
      .catch((error) => {
        console.warn(error);
        history.push("/retry-photo");
      });
  }

  if (processing) {
    return <ProcessingScreen />;
  }

  return (
    <div className={classes.content}>
      <PatchCaptureHeader />
      <Typography variant="h3">{t("examples.title")}</Typography>
      <Typography variant="body1">{t("examples.description")}</Typography>

      <img
        className={classes.image}
        alt="Good Example 1"
        width="270"
        height="307"
        src={correctPhoto1}
      />

      <img
        className={classes.image}
        alt="Good Example 2"
        width="270"
        height="307"
        src={correctPhoto2}
      />

      <img
        className={classes.image}
        alt="Good Example 3"
        width="270"
        height="307"
        src={correctPhoto3}
      />

      <div className={classes.footer}>
        <RegButton className={classes.cta}>
          <TakePhotoButton
            onPhoto={handlePhoto}
            type="PATCH"
            setPatchPhoto={setPatchPhoto}
            className={classes.btn}
          >
            {t("examples.openCamera")}
          </TakePhotoButton>
        </RegButton>

        <Link
          to="/how-to-use/sweat-patch-overview"
          className={classes.footerLink}
        >
          {t("examples.howToApply")}
        </Link>
      </div>
    </div>
  );
}

export default withStyles(styles)(BeforeYouStartScreen4);
