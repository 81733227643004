import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  CircularProgress,
  TextField,
  FormControlLabel,
  FormLabel,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { OutboundLink } from "react-ga";
import { colors } from "../theme";
import AppBar from "../components/AppBar";
import Divider from "../components/Divider";
import ProfilePictureButton from "../components/ProfilePictureButton";
import RegButton from "../components/RegButton";
import { ReactComponent as WhatsApp } from "../images/icons/whatsapp.svg";
import activities from "../common/activities";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 96,
      display: "flex",
      flexDirection: "column",
    },
    profilePicture: {
      marginTop: 10,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      lineHeight: "25px",
      color: colors.darkishGrey,
      fontSize: 18,
    },
    profileButton: {
      width: 100,
      marginRight: 24,
    },
    formControl: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: "100%",
    },
    cta: {
      marginTop: 24,
      marginBottom: 48,
      width: "100%",
      textAlign: "center",
    },
    ctaButton: {
      width: "100%",
      background: colors.black,
      color: colors.white,
    },
    disabledButton: {
      width: "100%",
      background: colors.white,
    },
    inputField: {
      width: "100%",
      textTransform: "capitalize",
      padding: 4,
      "& > label": {
        marginLeft: -10,
        fontSize: "1.1em",
      },
    },
    progress: {
      color: colors.orange,
    },
  })
);

function ConfirmSweatTestDetails({
  firstName,
  lastName,
  activity,
  duration,
  name,
  postalCode,
  facilityType,
  gender,
  weight,
  age,
  phone,
  patchPhoto,
  profilePicture,
  country,
  email,
  sodium,
  fluid,
  handleSubmit,
  apiData,
  errorMessage,
  loading
}: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [termsAgree, setTermsAgree] = useState<boolean>(false);
  const [privacyAgree, setPrivacyAgree] = useState<boolean>(false);

  const valid = () => termsAgree && privacyAgree;

  useEffect(() => {
    if (loading && apiData) {
      if (apiData.date === "MANUAL") {
        history.push("/thank-you");
      } else {
        history.push(`/results/${apiData.date}/${apiData.id}`);
      }
    }
  }, [apiData, history, loading]);

  const hrs = Math.floor(parseInt(duration) / 60);
  const mins = parseInt(duration) % 60;

  return (
    <div>
      <AppBar backTo="/athlete" />
      <div className={classes.content}>
        <Typography variant="h3" gutterBottom>
          {t("confirmDetails.title")}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {t("confirmDetails.activityConfirm")}
        </Typography>

        <div className={classes.formControl}>
          <TextField
            value={
              activity && t(`activities.${activities[activity]?.toLowerCase()}`)
            }
            label={t("activity.activity")}
            className={classes.inputField}
            disabled
          />
        </div>

        <div className={classes.formControl}>
          <TextField
            value={`${hrs}h ${mins}min`}
            label={t("activity.duration")}
            className={classes.inputField}
            disabled
          />
        </div>

        <div className={classes.formControl}>
          <TextField
            value={country && t(`countries.${country}`)}
            label={t("activity.country")}
            className={classes.inputField}
            disabled
          />
        </div>

        {postalCode && (
          <div className={classes.formControl}>
            <TextField
              value={postalCode}
              label={t("activity.postalCode")}
              className={classes.inputField}
              disabled
            />
          </div>
        )}

        <div className={classes.formControl}>
          <TextField
            value={
              facilityType.substring(0, 1) +
              facilityType.substring(1, 10).toLowerCase()
            }
            label={`${t("activity.indoor")} / ${t("activity.outdoor")}`}
            className={classes.inputField}
            disabled
          />
        </div>

        <Typography variant="body1" gutterBottom>
          {t("confirmDetails.athleteConfirm")}
        </Typography>

        <div className={classes.formControl}>
          <TextField
            value={firstName}
            label={t("athlete.firstName")}
            className={classes.inputField}
            disabled
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            label={t("athlete.lastName")}
            value={lastName}
            className={classes.inputField}
            disabled
          />
        </div>

        <div className={classes.formControl}>
          <TextField
            label={t("athlete.sex")}
            value={t(`athlete.${gender.toLowerCase()}`)}
            className={classes.inputField}
            disabled
          />
        </div>

        <div className={classes.formControl}>
          <TextField
            value={age}
            label={t("athlete.age")}
            className={classes.inputField}
            disabled
          />
        </div>

        <div className={classes.formControl}>
          <TextField
            label={t("athlete.weight")}
            className={classes.inputField}
            value={weight && weight + "kg"}
            disabled
          />
        </div>

        <div className={classes.formControl}>
          <FormLabel>{t("athlete.number")}</FormLabel>

          <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
            <WhatsApp />
            <TextField type="tel" value={phone && phone} disabled />
          </div>
        </div>

        <div className={classes.profilePicture}>
          <div className={classes.profileButton}>
            <ProfilePictureButton
              disabled={true}
              profilePicture={profilePicture}
            />
          </div>
          <div>{t("athlete.profilePic")}</div>
        </div>
        <Divider />

        <Typography variant="body1">{t("athlete.notifyThruEmail")}</Typography>

        <div className={classes.formControl}>
          <TextField
            type="email"
            value={email}
            label={t("athlete.email")}
            className={classes.inputField}
          />
        </div>

        <div className={classes.formControl}>
          <Typography variant="body1">
            {t("confirmDetails.confirmTermsAndPrivacy")}
          </Typography>
        </div>

        <div className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                name="termsAgree"
                checked={termsAgree}
                onChange={() => setTermsAgree(!termsAgree)}
              />
            }
            label={
              <span>
                {t("confirmDetails.agree")}
                <OutboundLink
                  eventLabel="OptIn"
                  to="https://www.gxsweatpatch.com/#/terms"
                  target="_blank"
                >
                  {t("confirmDetails.terms")}
                </OutboundLink>
              </span>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                name="privacyAgree"
                checked={privacyAgree}
                onChange={() => setPrivacyAgree(!privacyAgree)}
              />
            }
            label={
              <span>
                {t("confirmDetails.agree")}
                <OutboundLink
                  eventLabel="OptIn"
                  to="https://www.pepsico.com.br/pt-br/privacidade"
                  target="_blank"
                >
                  {t("confirmDetails.privacy")}
                </OutboundLink>
              </span>
            }
          />
        </div>

        <div className={classes.cta}>
          <RegButton
            onClick={() => {
              handleSubmit()
            }}
            className={valid() ? classes.ctaButton : classes.disabledButton}
            disabled={!valid()}
          >
            {loading ? (
              <CircularProgress size={28} className={classes.progress} />
            ) : (
              t("athlete.next")
            )}
          </RegButton>

          {errorMessage && (
            <Typography variant="caption" color="error">
              {errorMessage}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConfirmSweatTestDetails;
