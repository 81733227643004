import Baseball from "../images/icons/sports/baseball.png";
import Basketball from "../images/icons/sports/basketball.png";
import Elliptical from "../images/icons/sports/elliptical.png";
import Football from "../images/icons/sports/football.png";
import Hike from "../images/icons/sports/hike.png";
import Lacrosse from "../images/icons/sports/lacrosse.png";
import Ride from "../images/icons/sports/ride.png";
import Row from "../images/icons/sports/row.png";
import Running from "../images/icons/sports/running.png";
import Soccer from "../images/icons/sports/soccer.png";
import Tennis from "../images/icons/sports/tennis.png";
import Volleyball from "../images/icons/sports/volleyball.png";
import Walk from "../images/icons/sports/walk.png";
import Weights from "../images/icons/sports/weights.png";
import Wheelchair from "../images/icons/sports/wheelchair.png";
import Yoga from "../images/icons/sports/yoga.png";

const activities = [
  "RUNNING",
  "CYCLING",
  "BASEBALL",
  "BASKETBALL",
  "ELLIPTICAL",
  "FOOTBALL",
  "HIIT",
  "HIKE",
  "LACROSSE",
  "OTHER",
  // "RIDE",
  "ROW",
  // "RUN",
  "SOCCER",
  "TENNIS",
  "VOLLEYBALL",
  "WALK",
  "WEIGHTS",
  "WHEELCHAIR",
  "YOGA",
];

export const icons = [
  { id: "BASEBALL", src: Baseball },
  { id: "BASKETBALL", src: Basketball },
  { id: "CYCLING", src: Ride },
  { id: "ELLIPTICAL", src: Elliptical },
  { id: "FOOTBALL", src: Football },
  { id: "HIIT", src: Weights },
  { id: "HIKE", src: Hike },
  { id: "LACROSSE", src: Lacrosse },
  { id: "RIDE", src: Ride },
  { id: "ROW", src: Row },
  { id: "RUNNING", src: Running },
  { id: "SOCCER", src: Soccer },
  { id: "TENNIS", src: Tennis },
  { id: "VOLLEYBALL", src: Volleyball },
  { id: "WALK", src: Walk },
  { id: "WEIGHTS", src: Weights },
  { id: "WHEELCHAIR", src: Wheelchair },
  { id: "YOGA", src: Yoga },
];

export default activities;
