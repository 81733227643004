import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import Divider from "../../components/Divider";
import { ReactComponent as HelpIcon } from "../../images/icons/help.svg";
import { colors } from "../../theme";
import ResultsSpectrum from "../../components/ResultsSpectrum";
import fluids from "../../common/fluids";
import FluidScale from "../../components/FluidScale";

type SecondScreenProps = {
  sodiumLossDescriptor: string;
  totalSodiumLoss: number;
  totalSweatLoss: number;
  onHelp: (el: string) => void;
  classes: any;
};
const styles = createStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  results: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    background: colors.grey100,
    padding: 24,
    borderRadius: 8,
    minHeight: 418,
  },
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "50%",
  },
  fullCol: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  helpBtn: {
    marginTop: -12,
    marginLeft: 8,
    width: 20,
    height: 20,
  },
  logo: {
    position: "absolute",
    top: 24,
    left: 24,
  },
  avatar: {
    width: 131,
    height: 131,
    marginBottom: 12,
  },
  name: {
    fontFamily: "Helvetica",
    fontWeight: 900,
    fontSize: 24,
    letterSpcaing: "-0.27px",
    color: colors.orange,
    textTransform: "capitalize",
    marginBottom: 4,
  },
  weight: {
    textAlign: "center",
    fontFamily: "Helvetica",
    fontSize: 16,
    letterSpacing: "-0.18px",
    color: colors.grey,
    marginBottom: 21,
  },
  durationIcon: {
    "& .fillable": {
      fill: colors.grey,
    },
    height: 16,
    width: 14,
  },
  attributes: {
    fontFamily: "Helvetica",
    fontWeight: 500,
    fontSize: 16,
    textTransform: "capitalize",
    letterSpacing: "-0.18px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  attribute: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  value: {
    color: "#eee",
  },
  iconContainer: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 4,
  },
  sweat: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  patchPhoto: {
    width: 101,
    height: 101,
    marginRight: 10,
  },
  sweatResults: {
    marginLeft: 6,
  },
  resultType: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Helvetica",
    fontSize: 16,
    fontWeight: 500,
    textTransform: "capitalize",
    letterSpacing: "-0.18px",
    color: "#eee",
    marginBottom: 6,
  },
  resultTypeIcon: {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 3,
  },
  resultValue: {
    fontFamily: "Helvetica",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    paddingLeft: 27,
  },
  scalar: {
    fontSize: 48,
    fontWeight: 900,
    letterSpacing: "-0.55px",
    color: "#fff",
    marginRight: 6,
  },
});

function SecondScreen({
  classes,
  sodiumLossDescriptor,
  totalSodiumLoss,
  totalSweatLoss,
  onHelp,
}: SecondScreenProps) {
  const { t } = useTranslation();
  const isTinyPhone = useMediaQuery("(max-width:360px)");

  const lowSLVal = Math.round(totalSodiumLoss / 100) * 100 - 100;
  const highSLVal = Math.round(totalSodiumLoss / 100) * 100 + 100;

  const fluidIdx =
    Math.floor(totalSweatLoss / 150) > 10
      ? 10
      : Math.floor(totalSweatLoss / 150);

  return (
    <div className={classes.root}>
      <div className={classes.results}>
        <div className={isTinyPhone ? classes.fullCol : classes.row}>
          <div className={classes.col}>
            <div style={{ marginBottom: 56 }}>
              <div className={classes.header}>
                <Typography variant="subtitle1">
                  {t("results.fluidLoss")}
                </Typography>
                <div
                  className={classes.helpBtn}
                  onClick={() => onHelp("FLUID_LOSS")}
                >
                  <HelpIcon />
                </div>
              </div>
              <Typography variant="body1">{t("results.approxML")}</Typography>
            </div>
            <div style={{ flex: 1 }}></div>
            <div style={{ justifySelf: "flex-end", marginTop: 48 }}>
              <Typography variant="h5" style={{ lineHeight: "1em" }}>
                ~{Math.round(totalSweatLoss)}ml
              </Typography>
            </div>
          </div>
          <div className={classes.col}>
            <div style={{ position: "relative", top: 36 }}>
              <img
                src={fluids[fluidIdx].src}
                alt={t("results.fluidLoss")}
                width="125"
                height="179"
              />
              <FluidScale />
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <div className={classes.header}>
            <Typography variant="subtitle1">
              {t("results.sodiumLoss")}
            </Typography>
            <div
              className={classes.helpBtn}
              onClick={() => onHelp("SODIUM_LOSS")}
            >
              <HelpIcon />
            </div>
          </div>
          <Typography variant="body1">{t("results.approxMG")}</Typography>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 24,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "capitalize",
            }}
          >
            <Typography variant="h5">
              {sodiumLossDescriptor.toLowerCase()}
            </Typography>
          </div>
          <ResultsSpectrum
            lowVal={lowSLVal}
            highVal={highSLVal}
            range={[0, 1300]}
            label="mg"
          />
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SecondScreen);
