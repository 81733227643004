import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { colors } from "../theme";
import { ReactComponent as ExpandIcon } from "../images/icons/plus.svg";
import { ReactComponent as CollapseIcon } from "../images/icons/minus.svg";

const Accordion = withStyles({
  root: {
    border: "none",
    background: "transparent",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    color: colors.darkishGrey,
  },
})(MuiAccordionDetails);

function FAQ({ questions, className }: any) {
  const [index, setIndex] = useState<number>(-1);
  return (
    <div className={className}>
      {questions.map(([question, answer]: any, idx: number) => (
        <Accordion
          key={question}
          expanded={index === idx}
          onChange={() => {
            index === idx ? setIndex(-1) : setIndex(idx)
          }}
        >
          <AccordionSummary
            expandIcon={index === idx ? <CollapseIcon /> : <ExpandIcon />}
          >
            <Typography variant="subtitle1">{question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default FAQ;
