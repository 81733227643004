import React from "react";
import { withStyles, createStyles, Typography } from "@material-ui/core";

import background from "../images/photos/desktop.jpg";
import QRCode from "../images/gxqrcode.png";
import { colors } from "../theme";

const styles = createStyles({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 33,
    boxSizing: "border-box",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 0%",
  },
  content: {
    width: 420,
    position: "fixed",
    left: 100,
    top: 48,
  },
  body: {
    margin: "24px 0px",
  },
  link: {
    color: colors.orange,
  },
  qrCode: {
    height: 250,
    width: 250,
  },
});

function DesktopScreen({ classes }: any) {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h2" gutterBottom>
          Welcome to the
          <br />
          Gx Sweat Test
        </Typography>
        <div className={classes.body}>
          <Typography variant="subtitle1">
            Please open{" "}
            <a href="gxsweatpatch.com" className={classes.link}>
              gxsweatpatch.com
            </a>{" "}
            on your mobile phone browser or scan the QR code.
          </Typography>
        </div>

        <img src={QRCode} alt="QR Code" className={classes.qrCode} />
      </div>
    </div>
  );
}

export default withStyles(styles)(DesktopScreen);
