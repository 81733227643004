import { createMuiTheme } from "@material-ui/core/styles";

export const colors = {
  orange: "#FA5000",
  otherOrange: "#FF6B00",
  darkGrey: "#353535",
  darkishGrey: "#696969",
  grey: "rgba(0,0,0,0.25)",
  good: "#2fc17b",
  bad: "#e32a0e",
  purple: "#b05ee5",
  black: "#000000",
  white: "#FFFFFF",
  grey100: "#EEEEEE",
  lowFluidRate: "#FFCC26",
  highFluidRate: "#FF7945",
  lowSodium: "#D38CC3",
  highSodium: "#E0CCDB",
  lowSweatRate: "#8EE165",
  medSweatRate: "#FFFB00",
  highSweatRate: "#FE764F",
  errorRed: "#F90000",
};

export const typography = {
  fontFamily: ["Helvetica Now", "Helvetica", "sans-serif"].join(","),
  h1: {
    fontFamily: "Helvetica Now",
    fontSize: 52,
    lineHeight: "55px",
    letterSpacing: "-0.02em",
  },
  h2: {
    fontFamily: "Helvetica Now",
    fontSize: 40,
    lineHeight: "45px",
    letterSpacing: "-0.005em",
  },
  h3: {
    fontFamily: "Helvetica Now",
    fontSize: 28,
    lineHeight: "35px",
    letterSpacing: "0.01em",
  },
  h4: {
    fontFamily: "Helvetica Now",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: "28px",
    marginBottom: 0,
    letterSpacing: "0.01em",
  },
  h5: {
    fontFamily: "Helvetica Now",
    fontSize: 28,
    lineHeight: "35px",
    letterSpacing: "0.01em",
  },
  overline: {
    fontFamily: "Plexes Pro",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: "0.01em",
    marginTop: "20px",
  },
  subtitle1: {
    fontFamily: "Helvetica Now",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "25px",
  },
  body1: {
    fontFamily: "Helvetica Now",
    color: colors.darkishGrey,
    fontSize: 18,
    lineHeight: "25px",
  },
};

const theme = createMuiTheme({
  typography,
  palette: {
    type: "light",
    primary: {
      main: colors.orange,
    },
    secondary: {
      main: colors.orange,
    },
  },
  spacing: [0, 4, 8, 16, 24, 32, 64],
  props: {
    MuiTextField: {
      variant: "outlined",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: colors.black,
        borderColor: colors.black,
        borderWidth: 1,
        borderStyle: "solid",
        fontFamily: "Helvetica Now",
        fontWeight: 500,
        fontSize: 18,
        borderRadius: "999px",
        textTransform: "inherit",
        textAlign: "center",
        "&$disabled": {
          borderColor: colors.darkishGrey,
          color: colors.darkishGrey,
        },
        "&:hover": {
          borderColor: colors.darkishGrey,
          backgroundColor: colors.darkishGrey,
        },
      },
      text: {
        padding: 12,
      },
    },
    MuiCollapse: {
      container: {
        whiteSpace: "pre-line",
      },
    },
    MuiPaper: {
      root: {
        boxShadow: "0px 0px 4px rgba(0,0,0,0.5)",
        borderRadius: "999px",
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.darkishGrey,
        fontFamily: "Helvetica Now",
        "&$focused": {
          color: colors.darkGrey,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginTop: 24,
      },
      label: {
        fontFamily: "Helvetica Now",
        color: colors.darkGrey,
        fontWeight: 500,
      },
    },
    MuiAccordionSummary: {
      root: {
        borderTop: "1px solid rgba(0, 0, 0, 0.25)",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
      input: {
        lineHeight: "35px",
        padding: "11px 0",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        fontSize: 28,
        borderBottom: "1px solid rgba(0,0,0,0.25)",
        "&$disabled": {
          color: colors.darkishGrey,
        },
      },
      notchedOutline: {
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: "4px 0px 0px",
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiInputBase: {
      root: {
        padding: 0,
      },
      input: {
        lineHeight: "35px",
        padding: "11px 0",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        fontSize: 28,
        borderBottom: "1px solid rgba(0,0,0,0.25)",
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: "transparent",
        borderBottom: 0,
        "&::before": {
          height: 0,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        color: colors.grey,
      },
    },
  },
});

export default theme;
