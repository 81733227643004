import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

type FluidScaleProps = {
  classes: any;
};
const styles = createStyles({
  scale: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: 12,
    color: "#696969",
    zIndex: 5,
    position: "absolute",
    height: "95%",
    width: "100%",
    textAlign: "right",
    top: 0,
    right: -60,
  },
});

function FluidScale({ classes }: FluidScaleProps) {
  const { t } = useTranslation();
  return (
    <div className={classes.scale}>
      <span
        style={{
          width: 100,
          borderBottom: "1px dotted gray",
          paddingBottom: 4,
        }}
      >
        {t("results.high")}
      </span>
      <span
        style={{
          width: 100,
          borderTop: "1px dotted gray",
          paddingTop: 4,
        }}
      >
        {t("results.low")}
      </span>
    </div>
  );
}

export default withStyles(styles)(FluidScale);
