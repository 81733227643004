import React from "react";
import { useTranslation } from "react-i18next";
import { createStyles, Typography, withStyles } from "@material-ui/core";
import Divider from "../../components/Divider";
import { ReactComponent as HelpIcon } from "../../images/icons/help.svg";
import SodiumLoss from "../../images/sodiumLoss.svg";
import FluidLoss from "../../images/sweatRate.svg";
import { colors } from "../../theme";
import ResultsSpectrum from "../../components/ResultsSpectrum";
import { icons } from "../../common/activities";

type FirstScreenProps = {
  activity: string;
  totalSweatLoss: number;
  totalSodiumLoss: number;
  totalSweatRate: number;
  duration: string;
  temperature: string;
  onHelp: (el: string) => void;
  classes: any;
};

const styles = createStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  results: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    background: colors.grey100,
    padding: 24,
    borderRadius: 8,
  },
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "50%",
  },
  header: {
    display: "flex",
    gap: 8,
    alignItems: "center",
  },
  helpBtn: {
    marginTop: -12,
    width: 20,
    height: 20,
  },
});

function FirstScreen({
  activity,
  totalSweatLoss,
  totalSodiumLoss,
  totalSweatRate,
  duration,
  temperature = "21.3",
  onHelp,
  classes,
}: FirstScreenProps) {
  const { t } = useTranslation();

  const activityIcon = icons.find((i) => i.id === activity)?.src;
  const hrs = Math.floor(parseInt(duration) / 60);
  const mins = parseInt(duration) % 60;

  const lowSRVal = Math.round(totalSweatRate / 100) * 100 - 100;
  const highSRVal = Math.round(totalSweatRate / 100) * 100 + 100;

  return (
    <div className={classes.root}>
      <div className={classes.results}>
        <Typography variant="subtitle1" gutterBottom>
          {t("results.resultsSnapshot")}
        </Typography>
        <Typography
          variant="body1"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={activityIcon}
            width="24"
            height="24"
            alt={activity}
            style={{ marginRight: 8 }}
          />{" "}
          {hrs}h {mins}min&nbsp;&nbsp;|&nbsp;&nbsp;{temperature}&deg;C
        </Typography>
        <div
          style={{ color: colors.darkishGrey, marginTop: 24, marginBottom: 24 }}
        >
          <Typography variant="body2">
            {t("results.lostDuringWorkout")}
          </Typography>
        </div>
        <div className={classes.row}>
          <div className={classes.col}>
            <img
              src={FluidLoss}
              alt={t("overview.fluidLossTitle")}
              width="30"
              height="10"
            />

            <Typography variant="subtitle1">
              {t("overview.fluidLossTitle")}
            </Typography>
            <Typography variant="h5">~{Math.round(totalSweatLoss)}ml</Typography>
          </div>
          <div className={classes.col}>
            <img
              src={SodiumLoss}
              alt={t("overview.sodiumLossTitle")}
              width="30"
              height="10"
            />

            <Typography variant="subtitle1">
              {t("overview.sodiumLossTitle")}
            </Typography>
            <Typography variant="h5">~{Math.round(totalSodiumLoss)}mg</Typography>
          </div>
        </div>
        <Divider />
        <div className={classes.header}>
          <Typography variant="subtitle1">{t("results.sweatRate")}</Typography>
          <div className={classes.helpBtn} onClick={() => onHelp("SWEAT_RATE")}>
            <HelpIcon />
          </div>
        </div>
        <Typography variant="body1">{t("results.approxMLH")}</Typography>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 24,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">~{Math.round(totalSweatRate)}</Typography>
          </div>
          <div style={{ width: "100%" }}>
            <ResultsSpectrum
              lowVal={lowSRVal}
              highVal={highSRVal}
              range={[0,1600]}
              label="ml/h"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(FirstScreen);
