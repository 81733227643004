import React from "react";
import { OutboundLink } from "react-ga";
import {
  Button,
  withStyles,
  createStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { colors } from "../theme";
import { useTranslation } from "react-i18next";

import Div100vh from "react-div-100vh";

const styles = createStyles({
  root: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  content: {
    color: "black",
    "&:p": {
      color: "black",
    },
  },
  cta: {
    marginTop: 24,
    marginBottom: 48,
    width: "100%",
    textAlign: "center",
  },
  ctaButton: {
    width: "100%",
    background: colors.black,
    color: colors.white,
  },
});

function TermsScreen({ classes, firstName }: any) {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Div100vh className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h1" gutterBottom>TÉRMINOS DE USO</Typography>​
        <Typography variant="body1" gutterBottom>
          Por favor lea cuidadosamente las siguientes condiciones de Uso antes
          de usar el presente sitio web https://www.gxsweatpatch.com/#/ o
          portal.
        </Typography>
        <Typography variant="h2" gutterBottom>
          ACEPTACIÓN DE LOS TÉRMINOS
        </Typography>
        ​
        <Typography variant="body1" gutterBottom>
          Este Sitio web (el “Sitio”) es operado (directa o indirectamente) por
          Stokely-Van Camp, Inc. con oficinas en 555 West Monroe Street,
          Chicago, Illinois 60661 (“PepsiCo”). En todo el Sitio, los términos
          “nosotros”, “nos” y “nuestro” se refieren a PepsiCo. PepsiCo ofrece
          este Sitio, incluida toda la información, herramientas y servicios
          disponibles en este Sitio, para usted, el usuario. Si no está de
          acuerdo con estos términos de uso, por favor no utilice este Sitio. La
          versión actualizada de estas Condiciones se encuentra disponible al
          acceder al link denominado "Condiciones de Uso" localizado en la parte
          inferior del Sitio.
        </Typography>
        ​ ​
        <Typography variant="h2" gutterBottom>
          EXACTITUD Y TOTALIDAD DE LA INFORMACIÓN
        </Typography>
        ​
        <Typography variant="body1" gutterBottom>
          Aun cuando PepsiCo lleva a cabo todo su esfuerzo para asegurar que la
          información contenida en el Sitio sea cierta y confiable, PepsiCo no
          otorga ninguna garantía o representación alguna respecto de su
          veracidad, certeza, confiabilidad o de cualquier forma con respecto a
          dicha información y, en la mayor medida permitida por la ley, no asume
          responsabilidad alguna derivada de cualquier omisión o error en el
          contenido del Sitio. El material de este Sitio se proporciona sólo
          como información general y no debe utilizarse como la única base para
          tomar decisiones sin consultar fuentes de información primarias, más
          precisas, más completas u oportunas. Aplicable solo para Brasil:
          PepsiCo hará todo lo posible para incluir solo información precisa y
          actualizada en su sitio web. El material de este Sitio se proporciona
          solo para información general y no debe confiarse en él ni utilizarse
          como la única base para tomar decisiones sin consultar fuentes de
          información primarias, más precisas, más completas o más oportunas.
          Nos reservamos el derecho de modificar el contenido de este Sitio en
          cualquier momento, pero no tenemos la obligación de actualizar ninguna
          información en el Sitio o incluso de enviar avisos sobre dichos
          cambios. PepsiCo solo será responsable de las situaciones expresamente
          establecidas en la ley aplicable y en las que no se permita la
          renuncia a los derechos. Aplicación para Colombia: El material de este
          Sitio se proporciona sólo como información general y no debe
          utilizarse como la única base para tomar decisiones sin consultar
          fuentes de información primarias, más precisas, más completas e
          informadas. El Usuario es Responsable y acepta haber leído en su
          totalidad los presentes Términos y Condiciones por lo que declara fue
          informado de forma clara y completa sobre el material de Sitio y su
          correcto uso y aplicación.
        </Typography>
        ​
        <Typography variant="h2" gutterBottom>
          MODIFICACIONES DE EL SITIO
        </Typography>
        ​
        <Typography variant="body1" gutterBottom>
          PepsiCo revisará de forma periódica los contenidos de este Sitio. Nos
          reservamos el derecho de modificar el contenido de este Sitio en
          cualquier momento, sin perjuicio de que en caso de existir cambios
          sustanciales que estén en relación con tus derechos y obligaciones te
          enviaremos un aviso indicando dichos cambios y requiriendo tu
          aceptación, pero no tenemos la obligación de actualizar ninguna
          información incluida en el Sitio.{" "}
        </Typography>
        ​
        <Typography variant="h2" gutterBottom>
          USO DEL SITIO POR EL USUARIO
        </Typography>
        ​
        <Typography variant="body1" gutterBottom>
          Todo el contenido de este Sitio (incluidos, pero sin limitarse, al
          texto, diseño, gráficos, logotipos, íconos, imágenes, clips de audio,
          descargas, interfaces, código y software) es propiedad exclusiva de
          PepsiCo, sus matrices, subsidiarias y afiliadas, sus licenciantes o
          sus proveedores de contenido, según corresponda, está protegido por
          derechos de autor, derechos de propiedad industrial, marcarios y otras
          leyes aplicables. Puede acceder, copiar, descargar e imprimir el
          material contenido en el Sitio solamente para su uso personal, siempre
          que no modifique ni elimine ningún aviso de derechos de autor,
          marcarios u otro aviso de propiedad intelectual que aparezca en el
          material al que accede, copia, descarga o imprime, quedándole
          estrictamente prohibido cualquier uso comercial del contenido del
          Sitio. De forma meramente enunciativa, cualquier otro uso del
          contenido del Sitio, que incluye, pero no se limita a, la
          modificación, distribución, transmisión, carga, licencia o creación de
          trabajos derivados de cualquier material, información, software,
          productos o servicios obtenidos del Sitio, está expresamente
          prohibido. PepsiCo, sus matrices, subsidiarias y afiliadas, o sus
          licenciantes o proveedores de contenido conservan la propiedad total y
          completa del material proporcionado en el Sitio, incluidos todos los
          derechos de propiedad intelectual e industrial asociados, y le
          proporcionan este material bajo una licencia que es revocable en
          cualquier momento a la entera discreción de PepsiCo. PepsiCo no
          garantiza ni declara que el uso que haga de los materiales de este
          Sitio no infringirá los derechos de terceros no afiliados a PepsiCo.
        </Typography>
        ​
        <Typography variant="h2" gutterBottom>
          NO USO ILEGAL O PROHIBIDO
        </Typography>
        <Typography variant="body1" gutterBottom>
          Como condición para el uso del Sitio, usted acepta no usarlo para
          ningún propósito que sea ilegal o esté prohibido por estos términos de
          uso o cualquier ley aplicable.
        </Typography>
        <Typography variant="h2" gutterBottom>
          REGISTRO{" "}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Algunas de las funcionalidades del Sitio podrían requerir que usted se
          registre y cree una cuenta de usuario. Si desea registrarse en el
          Sitio, acepta y se obliga a ingresar información veraz de su persona,
          como se requiere en el formato de registro. Igualmente acepta que
          únicamente se registrará una vez. Nos reservamos el derecho de
          suspender o eliminar su registro, en el caso que usted incumpla con
          estos términos. Usted es responsable de mantener la confidencialidad
          de su cuenta de usuario y es responsable de todas las actividades que
          se realicen en su cuenta. Se compromete a notificarnos de forma
          inmediata de cualquier sospecha de uso no autorizado de su cuenta. En
          la mayor medida permitida por la ley, PepsiCo no será responsable de
          ninguna perdida o daño que resulte de la no protección, por su parte,
          de la confidencialidad de la información de su cuenta. Si no desea
          registrarse en el Sitio tendrá la opción en algunas ocasiones de
          ingresar como “Visitante”. Para ingresar como Visitante podrá
          solicitarse al usuario información (como lo es sin limitar, nombre,
          correo electrónico, información de pago y dirección). Dicha
          información únicamente se utilizará para contactarlo referente a el
          estatus de la orden de compra o para contestar sus preguntas.{" "}
        </Typography>
        <Typography variant="h2" gutterBottom>
          MATERIALES ENVIADOS AL SITIO
        </Typography>
        <Typography variant="body1" gutterBottom>
          Usted reconoce que es responsable de cualquier material que pueda
          enviar a través del Sitio, incluida su legalidad, confiabilidad,
          idoneidad, originalidad, derechos de autor o de propiedad
          intelectual/industrial de dicho material. No puede cargar, distribuir
          o publicar a través de este Sitio ningún contenido que (i) sea
          confidencial, patentado, falso, fraudulento, calumnioso, difamatorio,
          obsceno, amenazante, invasivo de los derechos de privacidad o
          publicidad, que infrinja los derechos de propiedad
          intelectual/industrial, abusivo, ilegal o controvertible; (ii) pueda
          constituir o alentar la comisión de un delito, violar los derechos de
          cualquier persona (natural o jurídica) o dar lugar a responsabilidad o
          violar cualquier ley; o (iii) pueda contener virus, campañas
          políticas, mensajes en cadena, correos masivos o cualquier forma de
          “spam”. No puede utilizar una dirección de correo electrónico falsa u
          otra información de identificación, hacerse pasar por otra persona o
          entidad o inducir a error en cuanto al origen de cualquier contenido.
          No puede cargar contenido comercial en el Sitio. Si usted envía
          cualquier material, y a menos que indiquemos lo contrario, usted
          otorga a PepsiCo, sus matrices, subsidiarias y sus afiliadas el
          derecho irrestricto, no exclusivo, gratuito, perpetuo, irrevocable y
          totalmente sub-licenciable para usar, reproducir, modificar, adaptar,
          publicar, traducir y crear trabajos derivados, y distribuir y exhibir
          dicho material en todo el mundo en cualquier medio. Además, acepta que
          PepsiCo, sus matrices, subsidiarias y afiliadas son libres de utilizar
          cualquier idea, concepto o conocimiento que usted o las personas que
          actúen en su nombre le proporcionen, sin pago de ninguna especie.
          Usted declara y garantiza que posee o controla todos los derechos y/o
          ha obtenido las autorizaciones necesarias sobre el contenido que
          publica; que el contenido es exacto; que el uso del contenido que
          proporciona no viola ninguna disposición de estos términos de uso y no
          causará daño a ninguna persona (natural o jurídica); y que indemnizará
          a PepsiCo, sus matrices, subsidiarias y afiliadas por todas las
          reclamaciones que resulten del contenido que suministre.
        </Typography>
        <Typography variant="h2" gutterBottom>
          POLÍTICA DE PRIVACIDAD
        </Typography>
        Usted otorga a PepsiCo, sus matrices, subsidiarias y afiliadas el
        derecho a utilizar el nombre que envía en relación con dicho material,
        si así lo desean. Toda la información personal proporcionada a través de
        este Sitio se manejará de acuerdo con la política de privacidad y
        cookies en línea del Sitio.
        <Typography variant="h2" gutterBottom>
          LIMITACIÓN DE RESPONSABILIDAD
        </Typography>
        <Typography variant="body1" gutterBottom>
          En la mayor medida permitida por la ley: a) en ningún caso y bajo
          ninguna teoría en derecho o en equidad, ya sea por responsabilidad
          contractual o extracontractual, objetiva, o cualquier otro tipo de
          responsabilidad, PepsiCo, sus matrices, subsidiarias y afiliadas serán
          responsables de cualquier daño directo, indirecto, especial,
          incidental o consecuencial que surja de cualquier uso de la
          información contenida en el Sitio, incluyendo pero sin limitarse, a
          los daños por lucro cesante o daño emergente, pérdida de buen nombre
          (goodwill), pérdida de datos, precisión de los resultados, falla o mal
          funcionamiento de su computador. b) ni PepsiCo, sus matrices,
          subsidiarias y afiliadas ni sus proveedores serán responsables de
          cualquier conducta difamatoria, ofensiva o ilegal de cualquier usuario
          del Sitio. Su único y exclusivo recurso respecto de cualquiera de los
          reclamos antes mencionados o cualquier disputa con PepsiCo, sus
          matrices, subsidiarias y afiliadas es que usted deje de usar el Sitio.
          Debido a que algunas jurisdicciones no permiten limitaciones respecto
          de la duración de una garantía implícita, o la exclusión o limitación
          de responsabilidad por daños, todo o una parte de las limitaciones
          antes mencionadas podrían no ser aplicables en su caso.{" "}
        </Typography>
        <Typography variant="h2" gutterBottom>
          INDEMNIDAD
        </Typography>
        <Typography variant="body1" gutterBottom>
          Usted acepta defender, indemnizar y mantener indemne a PepsiCo, sus
          matrices, subsidiarias y afiliadas de y contra todos y cada uno de los
          reclamos, daños, costos y gastos, incluidos los honorarios de
          abogados, que surjan y estén relacionados con su uso del Sitio.
        </Typography>
        <Typography variant="h2" gutterBottom>
          AVISOS DE DERECHOS DE AUTOR
        </Typography>
        <Typography variant="body1" gutterBottom>
          A menos que se indique lo contrario, las imágenes gráficas, los
          botones y el texto contenidos en este Sitio son propiedad exclusiva de
          PepsiCo, sus matrices, subsidiarias y afiliadas. Excepto para uso
          personal, estos artículos no pueden copiarse, distribuirse, mostrarse,
          reproducirse, transmitirse o utilizarse de ninguna forma ni por ningún
          medio, sea electrónico, mecánico, de fotocopiado, grabación o de otro
          modo sin el permiso previo y por escrito de PepsiCo.
        </Typography>
        <Typography variant="h2" gutterBottom>
          MARCAS
        </Typography>
        <Typography variant="body1" gutterBottom>
          Este Sitio incluye logotipos, identidades de marca y otras marcas
          comerciales y marcas de servicio (colectivamente, las “Marcas”) que
          son propiedad de PepsiCo, sus matrices, subsidiarias y afiliadas o sus
          licenciantes o proveedores de contenido o están licenciados por ellos.
          Todas estas Marcas son propiedad de sus respectivos dueños. Nada de lo
          contenido en este Sitio debe interpretarse como una concesión,
          licencia o derecho para usar cualquier Marca que se muestre en este
          Sitio sin el permiso por escrito de PepsiCo o de cualquier tercero que
          pueda ser dueño de una Marca que se muestra en el Sitio. PepsiCo se
          reserva todos los derechos no otorgados expresamente en el Sitio y su
          contenido. Este Sitio y todo su contenido, incluidos, entre otros,
          texto, diseño, gráficos, interfaces y código, y la selección y
          disposición de los mismos, está protegido como una compilación bajo
          las leyes de derechos de autor de los Estados Unidos y otros países.
        </Typography>
        <Typography variant="h2" gutterBottom>
          ENLACES A SITIOS DE TERCEROS
        </Typography>
        <Typography variant="body1" gutterBottom>
          Para conveniencia de los usuarios, este Sitio puede vincularse a otros
          sitios que pertenecen y son operados por terceros y no mantenidos por
          PepsiCo. Sin embargo, incluso si dichos terceros están afiliados a
          PepsiCo, PepsiCo no tiene control sobre estos sitios vinculados, todos
          los cuales tienen prácticas de recopilación de datos y privacidad
          independientes y políticas legales independientes de PepsiCo. PepsiCo
          no es responsable del contenido de los sitios vinculados y no hace
          ninguna declaración con respecto al contenido o la precisión del
          material en dichos sitios. La visualización de dichos sitios de
          terceros es bajo su propio riesgo.
        </Typography>
        <Typography variant="h2" gutterBottom>
          JURISDICCIÓN
        </Typography>
        <Typography variant="body1" gutterBottom>
          En la medida en que las leyes de su país de residencia lo permitan,
          todos los asuntos relacionados con este Sitio se rigen por las leyes
          del estado de Nueva York, EE. UU. En el evento en que las leyes de su
          país de residencia no permitan lo anterior, se entenderá que sus
          derechos y los asuntos relacionados con este Sitio, así como las
          acciones legales respectivas, se rigen por las leyes aplicables a su
          país de residencia. Si alguna parte de estos términos de uso se
          considera ilegal, nula o inaplicable, esa parte se considerará
          separable y se interpretará de acuerdo con la ley aplicable. Dicho
          término no afectará la validez y aplicabilidad de las disposiciones
          restantes. El hecho de que PepsiCo, sus matrices, subsidiarias y
          afiliadas no ejerzan sus derechos con respecto a un incumplimiento de
          estos términos de uso por parte suya o de otros no constituye una
          renuncia a tales derechos y no limitará los derechos de PepsiCo, sus
          matrices, subsidiarias y afiliadas con respecto a dicho incumplimiento
          o cualquier incumplimiento posterior.
        </Typography>
        ​
        <Typography variant="body1" gutterBottom>
          Última modificación: 07 de Septiembre de 2021
        </Typography>
        <hr/>
        <Typography variant="h1" gutterBottom>TERMOS DE USO</Typography>
        <Typography variant="body1" gutterBottom>Favor ler cuidadosamente os seguintes termos de uso e isenções de responsabilidade antes de usar este Website.</Typography>
        <Typography variant="h2" gutterBottom>Aceitação dos Termos</Typography>
        <Typography variant="body1" gutterBottom>Este website (o “Site”) é operado (direta ou indiretamente) pela Stokely-Van Camp, Inc. (“PepsiCo”). Em todo o Site, os termos “nós” e “nosso” referem-se à PepsiCo. A PepsiCo oferece este Site, incluindo todas as informações, ferramentas e serviços disponíveis neste Site, para você, o usuário, condicionado à sua aceitação de todos os termos, condições, políticas e avisos aqui estabelecidos. O uso por você deste Site constitui sua concordância com estes termos de uso. Caso não concorde com estes termos de uso, não utilize este Site.</Typography>
        <Typography variant="h2" gutterBottom>Exatidão e Integridade das Informações</Typography>
        <Typography variant="body1" gutterBottom>A PepsiCo não se responsabiliza caso as informações disponibilizadas neste Site não forem precisas, completas ou atuais. O material neste Site é fornecido apenas para informação geral e não deverá ser tomado como base nem usado como a única fonte para a tomada de decisões, sem consultar as fontes de informação primárias mais precisas, mais completas ou mais atualizadas. Nós nos reservamos o direito de modificar o conteúdo deste Site a qualquer momento, mas não temos nenhuma obrigação de atualizar as informações no Site. Você concorda que é de sua responsabilidade monitorar as alterações feitas no Site.</Typography>
        <Typography variant="body1" gutterBottom><u>Aplicável apenas ao Brasil:</u> A PepsiCo envidará seus melhores esforços para incluir apenas informações precisas e atualizadas em seu website. O material neste Site é fornecido apenas para informação geral e não deverá ser tomado como base nem usado como a única fonte para a tomada de decisões, sem consultar as fontes de informação primárias mais precisas, mais completas ou mais atualizadas. Nós nos reservamos o direito de modificar o conteúdo deste Site a qualquer momento, mas não temos nenhuma obrigação de atualizar as informações no Site ou enviar notificações sobre essas modificações. A PepsiCo somente se responsabilizará pelas situações expressamente estabelecidas na legislação aplicável e nas quais a renúncia a direitos não seja permitida.</Typography>
        <Typography variant="h2" gutterBottom>Seu Uso do Site</Typography>
        <Typography variant="body1" gutterBottom>Todo o conteúdo deste Site (incluindo, entre outros, texto, design, gráficos, logotipos, ícones, imagens, clipes de áudio, downloads, interfaces, código e software) é de titularidade e propriedade exclusiva da PepsiCo, de sua empresa controladora, subsidiárias e afiliadas, licenciados ou seus provedores de conteúdo, e está protegido pelas leis de direitos autorais, marcas registradas e outras leis aplicáveis. Você poderá acessar, copiar, baixar e imprimir o material contido no Site para seu uso pessoal e não comercial, desde que você não modifique ou exclua nenhum aviso de direito autoral, marca registrada ou outro aviso de propriedade que aparecer no material que você acessar, copiar, baixar ou imprimir. Qualquer outro uso de conteúdo no Site, incluindo, entre outros, modificação, distribuição, transmissão, upload, licenciamento ou criação de trabalhos derivativos de qualquer material, informação, software, produtos ou serviços obtidos do Site, é expressamente Proibido. A PepsiCo, sua empresa controladora, subsidiárias e afiliadas, ou seus licenciantes ou provedores de conteúdo mantêm a titularidade total e completa do material fornecido no Site, incluindo todos os direitos de propriedade intelectual associados, e fornecem este material a você mediante uma licença que é revogável a qualquer momento, a critério exclusivo da PepsiCo. A PepsiCo não garante nem declara que o uso de materiais neste Site não infringirá direitos de terceiros não afiliados à PepsiCo.</Typography>
        <Typography variant="body1" gutterBottom>Como condição de uso do Site, você não usará o Site para qualquer propósito que seja ilegal ou proibido por estes termos de uso ou por quaisquer leis aplicáveis.</Typography>
        <Typography variant="h2" gutterBottom>Materiais que Você Enviar</Typography>
        <Typography variant="body1" gutterBottom>Você reconhece que é responsável por quaisquer materiais que você enviar pelo Site, inclusive pela legalidade, confiabilidade, adequação, originalidade e direitos autorais de quaisquer destes materiais. Você não poderá fazer upload, distribuir ou de outra forma publicar por meio deste Site qualquer conteúdo que (i) seja confidencial, proprietário, falso, fraudulento, calunioso, difamatório, obsceno, ameaçador, invasor de privacidade ou de direitos de publicidade, violador de direitos de propriedade intelectual, abusivo, ilegal ou de outra forma questionável; (ii) possa constituir ou encorajar uma ofensa criminal, violar os direitos de qualquer parte ou de outra forma dar origem à responsabilidade ou violar qualquer lei; ou (iii) possa conter vírus de software, campanha política, correntes, mala direta ou qualquer forma de “spam”. Você não poderá usar um endereço de e-mail falso ou outras informações de identificação falsas, fazer-se passar por qualquer pessoa ou entidade ou de outra forma induzir a erro quanto à origem de qualquer conteúdo. Você não poderá carregar conteúdo comercial no Site.</Typography>
        <Typography variant="body1" gutterBottom>Se você efetivamente enviar material, e a menos que indiquemos de outra forma, você concede à PepsiCo, sua empresa controladora, subsidiárias e afiliadas um direito irrestrito, não exclusivo, livre de royalties, perpétuo, irrevogável e totalmente sublicenciável de usar, reproduzir, modificar, adaptar, publicar, traduzir, criar trabalhos derivados, distribuir e exibir esse material em todo o mundo, em qualquer mídia. Você também concorda que a PepsiCo, sua empresa controladora, subsidiárias e afiliadas poderão usar quaisquer ideias, conceitos, know-how que você ou indivíduos atuando em seu nome fornecerem à PepsiCo, sua empresa controladora, subsidiárias e afiliadas. Você concede à PepsiCo, sua empresa controladora, subsidiárias e afiliadas o direito de usar o nome que você enviar com relação a esse material, se elas assim escolherem. Todas as informações pessoais fornecidas por meio deste Site serão tratadas de acordo com a política de privacidade e uso de cookies on-line do Site. Você declara e garante que é titular ou de outra forma controla todos os direitos sobre o conteúdo que você postar; que o conteúdo é preciso; que o uso do conteúdo que você fornecer não viola nenhuma disposição contida neste instrumento e não causará danos a nenhuma pessoa ou entidade; e que você indenizará a PepsiCo, sua empresa controladora, subsidiárias e afiliadas por todas as reivindicações resultantes do conteúdo fornecido por você.</Typography>
        <Typography variant="h2" gutterBottom>Isenções de Responsabilidade</Typography>
        <Typography variant="body1" gutterBottom>O uso por você deste Site será por sua conta e risco.</Typography>
        <Typography variant="body1" gutterBottom>A PepsiCo renuncia expressamente a qualquer tipo de garantia, expressa ou tácita, com relação a qualquer questão relacionada a este Site, incluindo, entre outros, a garantia tácita de comercialização, adequação a qualquer propósito específico ou não violação.</Typography>
        <Typography variant="body1" gutterBottom>Se você fizer o download de qualquer material do Site, você o fará por sua conta e risco. Você será o único responsável por qualquer dano ao seu sistema computacional ou perda de dados que resulte do download de qualquer um desses materiais.</Typography>
        <Typography variant="h2" gutterBottom>Limitação de Responsabilidade</Typography>
        <Typography variant="body1" gutterBottom>Em nenhuma hipótese e sob nenhuma teoria jurídica ou em equidade, seja por responsabilidade civil, mediante contrato, responsabilidade objetiva ou de outra forma, a Pepsico, sua empresa controladora, subsidiárias e afiliadas serão responsáveis por quaisquer danos diretos, indiretos, especiais, incidentais ou emergentes decorrentes de qualquer uso das informações contidas neste instrumento, incluindo, entre outros, danos por lucros cessantes, perda de fundo de comércio, perda de dados, paralisação de trabalho, precisão de resultados ou falha ou mau funcionamento de computador.</Typography>
        <Typography variant="body1" gutterBottom>Você reconhece e concorda especificamente que nem a Pepsico, nem sua empresa controladora, subsidiárias, afiliadas e fornecedores serão responsáveis por qualquer conduta difamatória, ofensiva ou ilegal de qualquer usuário do Site. Seu único e exclusivo recurso relativo a qualquer uma das reivindicações acima ou qualquer controvérsia com a Pepsico, sua empresa controladora, subsidiárias e afiliadas será interromper o uso do Site. Você e a Pepsico, sua empresa controladora, subsidiárias e afiliadas concordam que qualquer causa de pedir decorrente ou relacionada ao Site deverá ser instaurada dentro de 1 (um) ano após a causa de pedir surgir ou estar definitivamente prescrita. Como algumas jurisdições não permitam limitações sobre a duração de uma garantia tácita ou a exclusão ou limitação de responsabilidade por danos emergentes ou incidentais, toda ou parte da limitação acima não poderá se aplicar a você.</Typography>
        <Typography variant="h2" gutterBottom>Indenização</Typography>
        <Typography variant="body1" gutterBottom>Você obriga-se a defender, indenizar e isentar a PepsiCo, sua empresa controladora, subsidiárias e afiliadas de todas e quaisquer reivindicações, danos, custos e despesas, incluindo honorários advocatícios, decorrentes e relacionados ao uso do Site.</Typography>
        <Typography variant="h2" gutterBottom>Aviso de Direito Autoral</Typography>
        <Typography variant="body1" gutterBottom>A menos que indicado de outra forma, as imagens gráficas, botões e texto contidos neste Site são de propriedade exclusiva da PepsiCo, de sua empresa controladora, subsidiárias e afiliadas. Exceto para uso pessoal, esses itens não poderão ser copiados, distribuídos, exibidos, reproduzidos ou transmitidos, de forma algum ou por meio algum, seja eletrônico, mecânico, fotocópia, gravação ou outro, sem a permissão prévia e por escrito da PepsiCo.</Typography>
        <Typography variant="h2" gutterBottom>Marcas Comerciais</Typography>
        <Typography variant="body1" gutterBottom>Este Site apresenta logotipos, identidades de marca e outras marcas comerciais e marcas de serviço (conjuntamente, as “Marcas”), que são propriedade ou estão licenciadas para a PepsiCo, sua empresa controladora, subsidiárias e afiliadas ou seus licenciantes ou provedores de conteúdo. Todas essas Marcas são de propriedade de seus respectivos titulares. Nenhuma disposição contida neste Site deverá ser interpretada como uma concessão, por implicação, preclusão ou de outra forma, de qualquer licença ou direito de usar qualquer Marca exibida neste Site, sem permissão por escrito da PepsiCo ou de qualquer terceiro que seja titular de uma Marca exibida no Site. A PepsiCo reserva-se todos os direitos não expressamente concedidos no Site e em seu conteúdo. Este Site e todo o seu conteúdo, incluindo, entre outros, texto, design, gráficos, interfaces e código, e a seleção e organização destes são protegidos como uma compilação segundo as leis de direitos autorais dos Estados Unidos e de outros países.</Typography>
        <Typography variant="h2" gutterBottom>Links para Sites de Terceiros</Typography>
        <Typography variant="body1" gutterBottom>Como uma conveniência para os usuários, este Site poderá conter links para outros Sites pertencentes e operados por terceiros e não mantidos pela PepsiCo. No entanto, mesmo se esses terceiros forem afiliados à PepsiCo, a PepsiCo não tem controle sobre esses Sites vinculados, todos os quais têm práticas de privacidade e coleta de dados separadas e políticas legais independentes da PepsiCo. A PepsiCo não é responsável pelo conteúdo de nenhum dos sites vinculados, e não presta nenhuma declaração com relação ao conteúdo ou à precisão do material nesses sites. A visualização desses sites de terceiros correrá inteiramente por sua própria conta e risco.</Typography>
        <Typography variant="h2" gutterBottom>Foro</Typography>
        <Typography variant="body1">Na medida permitida pelas leis de seu país de residência, todas as questões relacionadas a este Site são regidas pelas leis do Estado de Nova York, EUA. Na hipótese de as leis de seu país de residência não permitirem o disposto acima, fica entendido que seus direitos e qualquer questão relacionada a este Site, bem como as respectivas ações judiciais, são regidos pelas leis aplicáveis ao seu país de residência. Se qualquer parte destes Termos de Uso for considerada ilegal, nula ou inexequível, aquela parte será considerada independente e deverá ser interpretada de acordo com as leis aplicáveis. Esse termo não afetará a validade e exequibilidade de quaisquer disposições remanescentes. A falha pela PepsiCo, por sua empresa controladora, subsidiárias e afiliadas em atuar com relação a uma violação destes termos de uso por você ou por outros não constitui uma renúncia e não limitará os direitos da PepsiCo, de sua empresa controladora, subsidiárias e afiliadas relacionados a essa violação ou quaisquer violações subsequentes.</Typography>
      </div>
    </Div100vh>
  );
}

export default withStyles(styles)(TermsScreen);
