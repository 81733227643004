interface HBSRPayload {
  activity: string;
  duration?: string;
  gender?: string;
  facilityType?: string;
  sweatRate?: number;
  temperature?: string;
  weight: number;
}

export const getSweatClassification = (sweatLoss: number) => {
    let classification = "VERY LOW";
    if (sweatLoss > 1600) {
      classification = "VERY HIGH";
    } else if (sweatLoss > 1200) {
      classification = "HIGH";
    } else if (sweatLoss > 1000) {
      classification = "MODERATE";
    } else if (sweatLoss > 700) {
      classification = "LOW";
    }
    return classification;
  };

export const getWholeBodySweatRate = (payload: HBSRPayload) => {
  let x1 = 0;
  if (payload.gender === "FEMALE") {
    x1 = 1;
  }
  const x2 = payload.weight || 100;
  let x3 = 0;
  let x4 = 0;
  let x5 = 0;
  let x6 = 0;
  let x7 = 0;
  let x8 = 0;
  let x9 = 0;
  let x10 = 0;
  let x11 = 0;
  let x12 = 0;

  const x13 = parseFloat(payload.duration || "60") / 60;
  const x14 = parseFloat(payload.facilityType === "OUTDOOR" && payload.temperature || "21.111");
  // let x15 = 0;
  // if (payload.version === 1) {
  //   x15 = 1;
  // }
  const x16 = payload.sweatRate || 3.0;

  switch (payload.activity) {
    case "BASKETBALL":
      x3 = 1;
      break;
    case "CYCLING":
      x4 = 1;
      break;
    case "FITNESS":
      payload.facilityType === "INDOOR" ? (x5 = 1) : (x6 = 1);
      break;
    case "FOOTBALL":
      x7 = 1;
      break;
    case "LACROSSE":
      x8 = 1;
      break;
    case "RUNNING":
      payload.facilityType === "INDOOR" ? (x9 = 1) : (x10 = 1);
      break;
    case "SOCCER":
      x11 = 1;
      break;
    case "TENNIS":
      x12 = 1;
      break;
    default:
      x10 = 1;
      break;
  }

  const genderCoefficient = -0.1636 * x1;
  const weightCoefficient = 0.00822 * x2;
  const sportsCoefficient =
    -0.261 * x3 +
    -0.2437 * x4 +
    -0.101 * x5 +
    -0.055 * x6 +
    -0.104 * x7 +
    -0.122 * x8 +
    0.008 * x9 +
    0.002 * x10 +
    -0.0965 * x11 +
    0.0089 * x12;
  const durationCoefficient = 0.0899 * x13;
  const tempCoeffecient = 0.01253 * x14;
  const sweatRateCoefficient = 0.3841 * x16;

  // For debugging
  if(false) {
    console.log("genderCoefficient", genderCoefficient);
    console.log("weightCoefficient", weightCoefficient);
    console.log("sportsCoefficient", sportsCoefficient);
    console.log("durationCoefficient", durationCoefficient);
    console.log("tempCoeffecient", tempCoeffecient);
    console.log("sweatRateCoefficient", sweatRateCoefficient);
  }

  const rateModel4 =
    -0.181 +
    genderCoefficient +
    weightCoefficient +
    sportsCoefficient +
    durationCoefficient +
    tempCoeffecient +
    // 0.2726 * x15 + // patch version
    sweatRateCoefficient;
  // - 0.2065 * x16 * x15; // unknown in model 4

  let classification = "LOW";
  if (rateModel4 * 1000 >= 1000) {
    classification = "HIGH";
  } else if (rateModel4 * 1000 >= 700) {
    classification = "MED";
  }

  return { rate: rateModel4 * 1000, classification };
};

export const getWholeBodySodiumRate = (
  sodium: number,
  sweatRateInLitersPerHour: number
) => {
  const wholeBodySweatChlorideInMmolL = 0.6418 * sodium + 16.372;
  const wholeBodySweatSodiumInMMolL =
    0.9248 * wholeBodySweatChlorideInMmolL + 3.755;
  const wholeBodySweatSodiumInMgL = 22.9898 * wholeBodySweatSodiumInMMolL;

  const wholeBodySweatSodiumRate =
    wholeBodySweatSodiumInMgL * sweatRateInLitersPerHour;

  return wholeBodySweatSodiumRate;
};

export const getSweatRateMgCm2Min = (
  sweat: number,
  duration_minutes: number
) => {
  const patchRadiusCm = 0.35;
  const collectionAreaCm2 = Math.PI * patchRadiusCm * patchRadiusCm;
  const patchFactor = 3; // Patch v2.0
  return sweat / (patchFactor * collectionAreaCm2) / duration_minutes;
};

const sodiumLossOverall = (wbSodiumMgPerL: number) => {
  if (wbSodiumMgPerL <= 696) {
    return "LOW";
  } else if (696 < wbSodiumMgPerL && wbSodiumMgPerL <= 898) {
    return "MED";
  } else if (wbSodiumMgPerL > 898) {
    return "HIGH";
  }
};

const sodiumLossAdults = (wbSodiumMgPerL: number) => {
  if (wbSodiumMgPerL <= 718) {
    return "LOW";
  } else if (718 < wbSodiumMgPerL && wbSodiumMgPerL <= 923) {
    return "MED";
  } else if (wbSodiumMgPerL > 923) {
    return "HIGH";
  }
};

const sodiumLossYouths = (wbSodiumMgPerL: number) => {
  if (wbSodiumMgPerL <= 639) {
    return "LOW";
  } else if (639 < wbSodiumMgPerL && wbSodiumMgPerL <= 809) {
    return "MED";
  } else if (wbSodiumMgPerL > 809) {
    return "HIGH";
  }
};

export const sodiumLossLevel = (age: number, wbSodiumMgPerL: number) => {
  if (!age) {
    return sodiumLossOverall(wbSodiumMgPerL);
  } else if (age >= 18) {
    return sodiumLossAdults(wbSodiumMgPerL);
  } else {
    return sodiumLossYouths(wbSodiumMgPerL);
  }
};

interface allCalculationsAndLevelsInput {
  gender: string, 
  weight: number, 
  activity: string, 
  duration: string, 
  facilityType: string, 
  temperature: string, 
  localFluid: string, 
  localSodium: string,
  age: number
}

export const allCalculationsAndLevels = (sweatTest: allCalculationsAndLevelsInput) => {
  const hbsr = getWholeBodySweatRate({
    gender: sweatTest.gender,
    weight: sweatTest.weight,
    activity: sweatTest.activity,
    duration: sweatTest.duration,
    facilityType: sweatTest.facilityType,
    temperature: sweatTest.temperature,
    sweatRate: getSweatRateMgCm2Min(
      parseFloat(sweatTest.localFluid),
      parseInt(sweatTest.duration)
    ),
  });

  const sodium = Math.floor(
    getWholeBodySodiumRate(parseFloat(sweatTest.localSodium), hbsr.rate) / 1000
  );

  const totalSweatLoss =
    Math.floor(hbsr.rate) * (parseInt(sweatTest.duration) / 60); // totalLoss(sweatTest?.fluid, sweatTest?.duration);
  const totalSodiumLoss = Math.floor(sodium); // totalLoss(sweatTest?.sodium, sweatTest?.duration);
  const totalSweatRate = Math.floor(hbsr.rate);
  const sweatClassification = getSweatClassification(hbsr.rate);
  const sodiumLossDescriptor = sodiumLossLevel(sweatTest.age, totalSodiumLoss)

  return { totalSweatLoss, totalSodiumLoss, totalSweatRate, sweatClassification, sodiumLossDescriptor } 
}
