import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import {
  createStyles,
  withStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useParams } from "react-router-dom";
import FirstScreen from "./FirstScreen";
import SecondScreen from "./SecondScreen";
import Modal from "../../components/Modal";
import ProfilePicture from "../../components/ProfilePicture";
import SwipeableViews from "react-swipeable-views";
import Div100vh from "react-div-100vh";
import { colors } from "../../theme";
import activities from "../../common/activities";
import {
  getWorkout,
  getWorkouts,
  sendEmail,
} from "../../api";

import {
  getWholeBodySweatRate,
  getWholeBodySodiumRate,
  getSweatRateMgCm2Min,
  sodiumLossLevel,
  getSweatClassification,
  allCalculationsAndLevels
} from "../../common/sweatCalculations"

const styles = createStyles({
  container: {
    maxWidth: 600,
    margin: "0px auto",
    overflow: "hidden",
  },
  mobileContainer: {
    margin: "none",
    "& h3": {
      fontSize: "5vw",
    },
  },
  loading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    position: "relative",
    padding: 24,
  },
  content: {
    position: "relative",
    padding: 0,
    marginTop: -48,
  },
  swipeState: {
    zIndex: 2,
    display: "flex",
    padding: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    width: 12,
    height: 12,
    marginLeft: 4,
    marginRight: 4,
    borderRadius: 6,
    display: "inline-block",
    boxSizing: "border-box",
  },
  unselectedCircle: {
    border: `1px solid ${colors.darkGrey}`,
  },
  selectedCircle: {
    backgroundColor: colors.black,
  },
  profilePic: {
    position: "absolute",
    right: 45,
    top: 36,
    zIndex: 2,
  },
});

const mockSweatTestData = {
  activity: "RUNNING",
  age: "22",
  country: "US",
  duration: "0",
  email: "",
  facilityType: "INDOOR",
  fluid: "0",
  gender: "MALE",
  name: "",
  phone: "",
  postalCode: "",
  profilePicture: "",
  sodium: "0",
  sodiumLossDescriptor: "LOW",
  temperature: "21.3",
  weight: 0,
  whole_body_sweat_rate: 0,
  whole_body_sodium_rate: 0,
  calculated_whole_body_fluid_loss: 0,
  calculated_whole_body_sodium_loss: 0,
};

interface SweatTestParams {
  activity: string;
  age: string;
  country?: string;
  duration: string;
  email: string;
  facilityType: string;
  fluid: string;
  gender: string;
  name: string;
  phone: string;
  postalCode?: string;
  profilePicture?: string;
  sodium: string;
  sodiumLossDescriptor: string;
  temperature: string;
  weight: number;
  whole_body_sweat_rate: number;
  whole_body_sodium_rate: number;
  calculated_whole_body_fluid_loss: number;
  calculated_whole_body_sodium_loss: number;
}

interface ResultsParams {
  date: string;
  id: string;
  debug?: string;
}

export const totalLoss = (sweatOrSodium: string, duration: string) =>
  Math.round(parseFloat(sweatOrSodium) / (parseFloat(duration) * 60));

function ResultsScreen({ classes }: any) {
  const { t, i18n } = useTranslation();
  const { date, id, debug }: ResultsParams = useParams();
  const csvLink = useRef<any>(null);
  const isMobile = useMediaQuery("(max-width:760px)");

  const [sweatTest, setSweatTest] =
    useState<SweatTestParams>(mockSweatTestData);
  const [swipeIndex, setSwipeIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState();
  const [modalDesc, setModalDesc] = useState();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [transactionData, setTransactionData] = useState<any[]>([]);

  useEffect(() => {
    getWorkout(date, id).then((response: any) => {
      if (response?.name) {
        setSweatTest(response);
        setLoaded(true);
      }
    });
  }, [date, id]);

  if (!loaded) {
    return (
      <Div100vh className={classes.loading}>
        <CircularProgress />
      </Div100vh>
    );
  }

  const totalSweatLoss = sweatTest.calculated_whole_body_fluid_loss;
  const totalSodiumLoss = sweatTest.calculated_whole_body_sodium_loss;
  const totalSweatRate = totalSweatLoss / (parseInt(sweatTest.duration) / 60);
  
  const formatForExport = (data: any) => {
    const ts = new Date(data.timestamp.seconds * 1000);
    const mappedDate = ts.toLocaleString("default");

    const hbsr = getWholeBodySweatRate({
      gender: data.gender,
      weight: data.weight,
      activity: activities[parseInt(data.activity)],
      duration: data.duration,
      facilityType: data.facilityType,
      temperature: data.temperature,
      sweatRate: getSweatRateMgCm2Min(
        parseFloat(data.fluid),
        parseInt(data.duration)
      ),
    });

    const localSodium = Math.floor(
      getWholeBodySodiumRate(parseFloat(data.sodium), hbsr.rate) / 1000
    );

    const localTotalSweatLoss =
      Math.floor(hbsr.rate) * (parseInt(data.duration) / 60); // totalLoss(sweatTest?.fluid, sweatTest?.duration);
    const localTotalSodiumLoss = Math.floor(localSodium); // totalLoss(sweatTest?.sodium, sweatTest?.duration);
    const localTotalSweatRate = Math.floor(hbsr.rate);
    const localSweatClassification =
      getSweatClassification(localTotalSweatRate);

    let formatted = {
      id: data.id,
      timestamp: mappedDate,
      activity: data.activity,
      duration: data.duration,
      countryCode: data.country || data.location.country,
      postalCode: data.postalCode,
      facilityType: data.facilityType,
      first_name: data.firstName,
      last_name: data.lastName,
      gender: data.gender,
      age: data.age,
      weight: data.weight,
      email: data.email,
      phone: data.phone,
      whole_body_fluid_loss: localTotalSweatLoss,
      whole_body_sweat_rate: localTotalSweatRate,
      whole_body_sodium_loss: localTotalSodiumLoss,
      sodium_loss_descriptor: sodiumLossLevel(data.age, localTotalSodiumLoss),
      sweat_loss_classification: localSweatClassification,
      temperature: data.temperature,
      humidity: data.humidity,
      results_url: `https://www.gxsweatpatch.com/#/results/${date}/${data.id}`,
    };
    return formatted;
  };

  const generateCSV = async (date: string) => {
    let formattedData: any[] = [];
    await getWorkouts(date).then((res) => {
      res.forEach((result) => {
        formattedData.push(formatForExport(result));
      });
    });
    setTransactionData(formattedData);
    csvLink?.current?.link.click();
  };

  const _handleOpen = (e: string) => {
    switch (e) {
      case "SWEAT_RATE":
        setModalHeader(t("results.sweatRate"));
        setModalDesc(t("results.sweatRateDesc"));
        break;
      case "FLUID_LOSS":
        setModalHeader(t("results.fluidLoss"));
        setModalDesc(t("results.fluidLossDesc"));
        break;
      case "SWEAT_LOSS":
        setModalHeader(t("results.sweatLoss"));
        setModalDesc(t("results.sweatLossDesc"));
        break;
      case "SODIUM_LOSS":
        setModalHeader(t("results.sodiumLoss"));
        setModalDesc(t("results.sodiumLossDesc"));
        break;
    }
    setOpen(true);
  };

  // Variable length for characters now solved with variable font size in mobile w/ vw unit
  // style={{ fontSize: sweatTest?.name.length >= 15 ? "24px" : "32px" }}
  return (
    <div className={isMobile ? classes.mobileContainer : classes.container}>
      <div className={classes.header}>
        <Typography variant="overline">{t("results.sweatProfile")}</Typography>
        <div
          style={{
            marginBottom: 24,
            marginTop: 4,
          }}
        >
          <Typography variant="h3">{sweatTest.name}</Typography>
        </div>

        <div className={classes.profilePic}>
          {sweatTest?.profilePicture ? (
            <ProfilePicture profilePicture={sweatTest?.profilePicture} />
          ) : null}
        </div>
      </div>
      <div className={classes.content}>
        {sweatTest && (
          <SwipeableViews
            index={swipeIndex}
            onChangeIndex={setSwipeIndex}
            enableMouseEvents
            threshold={24}
          >
            <div style={{ padding: 24 }}>
              <FirstScreen
                activity={
                  activities[parseInt(sweatTest?.activity)] || "RUNNING"
                }
                duration={sweatTest?.duration}
                temperature={sweatTest?.temperature}
                totalSweatLoss={totalSweatLoss}
                totalSodiumLoss={totalSodiumLoss}
                totalSweatRate={totalSweatRate}
                onHelp={(e) => _handleOpen(e)}
              />
            </div>
            <div style={{ padding: 24 }}>
              <SecondScreen
                sodiumLossDescriptor={
                  sodiumLossLevel(parseInt(sweatTest?.age), totalSodiumLoss) ||
                  "N/A"
                }
                totalSweatLoss={totalSweatLoss || 100}
                totalSodiumLoss={totalSodiumLoss || 100}
                onHelp={(e) => _handleOpen(e)}
              />
            </div>
          </SwipeableViews>
        )}
        <div className={classes.swipeState}>
          <div
            className={`${classes.circle} ${
              swipeIndex === 0
                ? classes.selectedCircle
                : classes.unselectedCircle
            }`}
          />
          <div
            className={`${classes.circle} ${
              swipeIndex === 1
                ? classes.selectedCircle
                : classes.unselectedCircle
            }`}
          />
        </div>

        {debug === "send_email" ? (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <button
              onClick={() =>
                console.log(
                  getWholeBodySweatRate({
                    gender: "MALE",
                    weight: 88,
                    activity: "BASKETBALL",
                    duration: "1",
                    facilityType: "OUTDOOR",
                    temperature: "20",
                    sweatRate: getSweatRateMgCm2Min(54.75, 60),
                  }),
                  getWholeBodySodiumRate(
                    31.086,
                    getSweatRateMgCm2Min(54.75, 60)
                  )
                )
              }
            >
              Test HBSR
            </button>
            <button
              onClick={() =>
                sendEmail({
                  name: sweatTest?.name.split(" ")[0],
                  email: sweatTest?.email,
                  lang: i18n.language,
                  date,
                  id,
                })
              }
            >
              Test Email
            </button>

            <button onClick={() => generateCSV(date)}>Get Daily CSVs</button>
            <CSVLink
              data={transactionData}
              filename={`GTR_SweatPatch_${date}.csv`}
              ref={csvLink}
              target="_blank"
            />
          </div>
        ) : null}

        <Modal
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          header={modalHeader}
          description={modalDesc}
        ></Modal>
      </div>
    </div>
  );
}

export default withStyles(styles)(ResultsScreen);
