import React, { useState, useEffect } from "react";
import ReactGA, { OutboundLink } from "react-ga";
import { Typography, createStyles, withStyles } from "@material-ui/core";
import { ReactComponent as HelpIcon } from "../images/icons/help.svg";
import { ReactComponent as CloseIcon } from "../images/icons/close.svg";
// import { colors } from "../theme";
import TroubleshootIssues from "./TroubleshootIssues";
import { useTranslation } from "react-i18next";

const styles = createStyles({
  icon: {
    width: 24,
    height: 24,
  },
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.58)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 11,
  },
  modal: {
    fontSize: 16,
    lineHeight: "20px",
    borderRadius: 8,
    boxShadow: "0,0,4px,rgba(0,0,0,0.5)",
    width: "calc(100% - 56px)",
    boxSizing: "border-box",
    backgroundColor: "rgba(255,255,255,1)",
    textAlign: "right",
    "& a": {
      color: "#000",
    },
  },
  modalContent: {
    textAlign: "left",
    width: "100%",
    boxSizing: "border-box",
    paddingRight: 32,
    paddingLeft: 32,
    paddingBottom: 24,
    "& p": {
      marginBottom: 48,
    },
  },
  closeIcon: {
    padding: 10,
  },
});

function HelpButton({ classes }: any) {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (showModal) {
      window.scrollTo(0, 0);
      ReactGA.modalview("gx-support");
    }
  }, [showModal]);
  const { t } = useTranslation();

  return (
    <div>
      <HelpIcon className={classes.icon} onClick={() => setShowModal(true)} />
      {showModal && (
        <div
          className={classes.modalContainer}
          onClick={() => setShowModal(false)}
        >
          <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setShowModal(false)}
            />
            <div className={classes.modalContent}>
              <Typography variant="h4">{t("support.title")}</Typography>
              <Typography variant="body1">
                {t("support.questions")}
                <OutboundLink
                  eventLabel="Email Support From Help Button"
                  to="mailto:gxsupport@smartdesignworldwide.com"
                >
                  {t("support.emailUs")}
                </OutboundLink>
              </Typography>
              <TroubleshootIssues />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(HelpButton);
