import React, { Suspense } from "react";
import { createBrowserHistory } from "history";
import {
  ThemeProvider,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Router from "./router";
import DesktopScreen from "./screens/DesktopScreen";
import theme from "./theme";
import ReactGA from "react-ga";
import GatoradeCircularProgress from "./components/GatoradeCircularProgress";
import {HoneybadgerErrorBoundary} from '@honeybadger-io/react'
import honeybadger from './honeybadger'

if (process.env.REACT_APP_GOOGLE_ANALYTICS && process.env.NODE_ENV !== "test") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS, {
    debug: process.env.NODE_ENV !== "production",
  });
}

const history = createBrowserHistory();

history.listen((location) => {
  if (location) {
    ReactGA.set({ page: location.hash });
    ReactGA.pageview(location.hash);
  }
});

const styles = createStyles({
  root: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});

export const Loader = () => (
  <div
    style={{
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <GatoradeCircularProgress />
  </div>
);

const App = ({ classes }: any) => {
  const isMobile = useMediaQuery("(max-width:760px)");
  const isResultsScreen = window.location.hash.includes('results'); 
  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          {isMobile || isResultsScreen ? (
            <div className={classes.root}>
              <Router />
            </div>
          ) : (
            <DesktopScreen />
          )}
        </ThemeProvider>
      </Suspense>
    </HoneybadgerErrorBoundary>
  );
};

export default withStyles(styles)(App);
