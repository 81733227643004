import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { withStyles, createStyles } from "@material-ui/core";
import { ReactComponent as DownIcon } from "../images/icons/down.svg";
import { ReactComponent as Check } from "../images/icons/check.svg";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import { colors } from "../theme";

interface LanguageProps {
  id: string;
  label: string;
  abbr: string;
}

const styles = createStyles({
  root: {},
  pop: {
    padding: "8px 16px",
    display: "flex",
    width: 80,
    fontSize: 15,
    justifyContent: "space-between",
    textTransform: "uppercase",
    borderColor: 'rgba(0,0,0,0.2)',
  },
  selection: {
    display: "flex",
    fontSize: 20,
    border: "none",
    width: 200,
    justifyContent: "space-between",
    "&:hover": {
      background: colors.grey,
    },
  },
});

function LanguageSelector({ classes }: any): JSX.Element {
  const { i18n } = useTranslation();
  const [openTranslations, setOpenTranslations] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenTranslations(true)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const languages = [
    {
      id: "en",
      label: "English (ENG)",
      abbr: "Eng",
    },
    {
      id: "es",
      label: "Español (ESP)",
      abbr: "Esp",
    },
    {
      id: "pt",
      label: "Português (POR)",
      abbr: "Por",
    },
  ];

  return (
    <div style={{ position: "absolute", top: 10, right: 10 }}>
      <Button className={classes.pop} onClick={handleClick}>
        <span style={{ marginRight: 4 }}>
          {
            languages.find((lang: LanguageProps) => lang.id === i18n.language)
              ?.abbr || "Eng"
          }
        </span>
        <DownIcon />
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openTranslations}
        onClose={handleClose}
      >
        <section style={{ padding: 8 }}>
          {languages.map((lang: LanguageProps) => {
            return (
              <Button
                key={lang.id}
                className={classes.selection}
                onClick={() => {
                  i18n.changeLanguage(lang.id);
                  setOpenTranslations(false);
                }}
              >
                {lang.label} {i18n.language === lang.id ? <Check /> : null}
              </Button>
            );
          })}
        </section>
      </Popover>
    </div>
  );
}

export default withStyles(styles)(LanguageSelector);
