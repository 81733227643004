import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  TextField,
  FormControl,
  FormLabel,
  Button,
  InputLabel,
  Select,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { colors } from "../theme";
import { Link } from "react-router-dom";
import AppBar from "../components/AppBar";
import Divider from "../components/Divider";
import ProfilePictureButton from "../components/ProfilePictureButton";
import { ReactComponent as WhatsApp } from "../images/icons/whatsapp.svg";
import { useTranslation } from "react-i18next";
import { codes } from "../common/countries";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 96,
      display: "flex",
      flexDirection: "column",
    },
    profilePicture: {
      marginTop: 10,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      lineHeight: "25px",
      color: colors.darkishGrey,
      fontSize: 18,
      gap: 16,
    },
    profileButton: {
      width: 100,
    },
    formControl: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      width: "100%",
    },
    cta: {
      marginTop: 24,
      marginBottom: 48,
      width: "100%",
      textAlign: "center",
    },
    ctaButton: {
      width: "100%",
      background: colors.black,
      color: colors.white,
    },
    disabledButton: {
      width: "100%",
      background: colors.white,
    },
    inputField: {
      width: "100%",
      "& > label": {
        marginLeft: -10,
        fontSize: "1.1em",
      },
    },
  })
);

const getPhonePrefix = (country: string) => {
  switch (country) {
    case "US":
      return "1";
    case "BR":
      return "55";
    case "MX":
      return "2";
    default:
      return "1";
  }
};

function AthleteScreen({
  setName,
  gender,
  setGender,
  age,
  setAge,
  weight,
  setWeight,
  setPhone,
  profilePicture,
  setProfilePicture,
  email,
  setEmail,
  country,
}: any) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phonePrefix, setPhonePrefix] = useState<string>(
    getPhonePrefix(country)
  );
  const [phoneSuffix, setPhoneSuffix] = useState<string>("");

  useEffect(() => {
    setName(firstName + " " + lastName);
  }, [firstName, lastName, setName]);

  useEffect(() => {
    setPhone("+" + phonePrefix + phoneSuffix);
  }, [phonePrefix, phoneSuffix, setPhone]);

  // TODO: Email validation
  function valid() {
    return (
      firstName &&
      lastName &&
      gender &&
      age &&
      weight &&
      // phone &&
      // phoneSuffix?.length === 10 &&
      email
    );
  }

  return (
    <div>
      <AppBar backTo="/activity" />

      <div className={classes.content}>
        <Typography variant="h3" gutterBottom>
          {t("athlete.title")}
        </Typography>

        <div className={classes.formControl}>
          <TextField
            value={firstName}
            label={t("athlete.firstName")}
            className={classes.inputField}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            label={t("athlete.lastName")}
            value={lastName}
            className={classes.inputField}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <FormControl>
          <InputLabel htmlFor="gender-selector">{t("athlete.sex")}</InputLabel>
          <Select
            native
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            inputProps={{
              name: "gender",
              id: "gender-selector",
            }}
          >
            <option></option>
            <option value="MALE">{t("athlete.male")}</option>
            <option value="FEMALE">{t("athlete.female")}</option>
          </Select>
        </FormControl>

        <div className={classes.formControl}>
          <TextField
            type="number"
            value={age}
            label={t("athlete.age")}
            className={classes.inputField}
            onChange={(e) => setAge(e.target.value)}
            inputProps={{
              pattern: "\\d*",
              min: 0,
              max: 100,
            }}
          />
        </div>

        <div className={classes.formControl}>
          <div style={{ display: "flex" }}>
            <TextField
              type="number"
              label={t("athlete.weight")}
              className={classes.inputField}
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              inputProps={{
                pattern: "\\d*",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                marginBottom: 16,
              }}
            >
              <InputAdornment position="end">kg</InputAdornment>
            </div>
          </div>
        </div>

        <div className={classes.formControl}>
          <FormLabel>{t("athlete.number")}</FormLabel>

          <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
            <WhatsApp />
            <Select
              native
              value={phonePrefix}
              style={{ width: 100 }}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                setPhonePrefix(e.currentTarget.value as string)
              }
              inputProps={{
                name: "phonePrefix",
                id: "phone-prefix",
              }}
            >

              <option value="1">+1</option>
              <option value="55">+55</option>
              {Object.values(codes).map((code: number) => (
                <option key={code} value={code}>+{code}</option>
              ))}
            </Select>

            <TextField
              type="tel"
              value={phoneSuffix}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                const result = e.currentTarget.value as string;
                setPhoneSuffix(result.replace(/\D/g, ""));
              }}
            />
          </div>
        </div>

        <div className={classes.profilePicture}>
          <div className={classes.profileButton}>
            <ProfilePictureButton
              profilePicture={profilePicture}
              onChange={setProfilePicture}
            />
          </div>
          <div>{t("athlete.profilePic")}</div>
        </div>
        <Divider />

        <Typography variant="body1">{t("athlete.notifyThruEmail")}</Typography>

        <div className={classes.formControl}>
          <TextField
            type="email"
            value={email}
            label={t("athlete.email")}
            className={classes.inputField}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className={classes.cta}>
          <Button
            component={Link}
            to="confirm-details"
            className={valid() ? classes.ctaButton : classes.disabledButton}
            disabled={!valid()}
          >
            {t("athlete.next")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AthleteScreen;
