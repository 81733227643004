import React from "react";
import { Avatar, createStyles, withStyles } from "@material-ui/core";
import { ReactComponent as ProfilePicture } from "../images/profile-picture.svg";
import { colors } from "../theme";
import UploadFileButton from "./UploadFileButton";

const styles = createStyles({
  circle: {
    background: "linear-gradient(#FF6B2B, #FF2779, #2E70DA)",
    height: 92,
    width: 92,
    borderRadius: 46,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    height: 90,
    width: 90,
    borderRadius: 45,
    backgroundColor: colors.grey100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 90,
    height: 90,
  },
});

function ProfilePictureButton({
  classes,
  profilePicture,
  onChange,
  disabled = false,
}: any) {
  return (
    <div className={classes.circle}>
      {disabled ? (
        <div className={classes.root}>
          {profilePicture ? (
            <Avatar src={profilePicture} className={classes.image} />
          ) : (
            <ProfilePicture />
          )}
        </div>
      ) : (
        <UploadFileButton
          accept="image/*"
          className={classes.root}
          onChange={disabled ? () => null : onChange}
          type="PROFILE_PIC"
        >
          {profilePicture ? (
            <Avatar src={profilePicture} className={classes.image} />
          ) : (
            <ProfilePicture />
          )}
        </UploadFileButton>
      )}
    </div>
  );
}

export default withStyles(styles)(ProfilePictureButton);
