import Drop0 from "../images/photos/fluid-loss/2x/Drop_0.png";
import Drop1 from "../images/photos/fluid-loss/2x/Drop_1.png";
import Drop2 from "../images/photos/fluid-loss/2x/Drop_2.png";
import Drop3 from "../images/photos/fluid-loss/2x/Drop_3.png";
import Drop4 from "../images/photos/fluid-loss/2x/Drop_4.png";
import Drop5 from "../images/photos/fluid-loss/2x/Drop_5.png";
import Drop6 from "../images/photos/fluid-loss/2x/Drop_6.png";
import Drop7 from "../images/photos/fluid-loss/2x/Drop_7.png";
import Drop8 from "../images/photos/fluid-loss/2x/Drop_8.png";
import Drop9 from "../images/photos/fluid-loss/2x/Drop_9.png";
import Drop10 from "../images/photos/fluid-loss/2x/Drop_10.png";

export const fluids = [
  { id: 0, src: Drop0 },
  { id: 1, src: Drop1 },
  { id: 2, src: Drop2 },
  { id: 3, src: Drop3 },
  { id: 4, src: Drop4 },
  { id: 5, src: Drop5 },
  { id: 6, src: Drop6 },
  { id: 7, src: Drop7 },
  { id: 8, src: Drop8 },
  { id: 9, src: Drop9 },
  { id: 10, src: Drop10 },
];

export default fluids;
