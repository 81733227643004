import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";

import { ReactComponent as GxLogo } from "../images/gx.svg";
import SweatRate from "../images/fluidLoss.svg";
import SodiumLoss from "../images/sodiumLoss.svg";
import FluidLoss from "../images/sweatRate.svg";
import Epicore from "../images/Epicore_logo_black.png";
import Strength from "../images/strength.jpg";
import Workout from "../images/workout_desktop.jpg";
import { ReactComponent as DownIcon } from "../images/icons/down.svg";

import Button from "@material-ui/core/Button";
import FAQ from "../components/FAQ";
import LanguageSelector from "../components/LanguageSelector";

import { colors } from "../theme";

const scrollToRef = (ref: any) =>
  window.scrollTo({
    left: 0,
    top: ref.current.offsetTop,
    behavior: "smooth",
  });

const styles = createStyles({
  root: {},
  intro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100vh",
  },
  top: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "120px 24px 48px",
  },
  welcome: {
    marginTop: 24,
  },
  header: {
    fontFamily: "Helvetica Now",
    fontWeight: 900,
    fontSize: 31,
    textTransform: "capitalize",
    marginTop: 23,
  },
  heading: {
    fontFamily: "Plexes Pro",
    textTransform: "uppercase",
    fontSize: 16,
  },
  aboutSection: {
    backgroundColor: colors.grey100,
    paddingTop: 40,
    paddingBottom: 80,
  },
  getBelowFold: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "Helvetica Now",
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "2em",
    "& svg": {
      marginTop: 16,
      marginBottom: 80,
    },
  },
  buttons: {
    display: "flex",
    alignItems: "baseline",
    padding: 16,
  },
  primaryButton: {
    padding: 16,
    marginBottom: 24,
    display: "flex",
    alignItems: "baseline",
    background: colors.black,
    color: colors.white,
    "&:hover": {
      background: colors.darkGrey,
    },
  },
  h2: {
    fontFamily: "Helvetica Now",
    fontSize: 49,
    lineHeight: "45px",
    position: "relative",
    marginRight: 24,
    marginLeft: 24,
    paddingTop: 24,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    color: colors.black,
    paddingTop: 40,
    marginRight: 24,
    marginLeft: 24,
  },
  ctas: {
    display: "flex",
    flexDirection: "column",
    marginRight: 24,
    marginLeft: 24,
  },
  hero: {
    background: colors.orange,
    color: colors.white,
    padding: 40,
    marginLeft: 24,
    marginRight: 24,
  },
  heroImg: {
    width: "auto",
    marginLeft: 24,
    marginRight: 24,
    "& img": {
      width: "100%",
    },
  },
  patchFeatures: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginTop: 20,
  },
  introCtas: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 48px)",
  },
});

function SplashScreen({ onConfirm, onLearnHow, classes }: any): JSX.Element {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const faq = [
    [t("faqs.question1"), t("faqs.answer1")],
    [t("faqs.question2"), t("faqs.answer2")],
    [t("faqs.question3"), t("faqs.answer3")],
    [t("faqs.question4"), t("faqs.answer4")],
    [t("faqs.question5"), t("faqs.answer5")],
    [t("faqs.question6"), t("faqs.answer6")],
    [t("faqs.question7"), t("faqs.answer7")],
    [t("faqs.question8"), t("faqs.answer8")],
  ];
  const aboutPatchRef = useRef(null);
  const ctas = (
    <>
      <Button
        className={classes.primaryButton}
        onClick={onConfirm}
        component={Link}
        to="/before-you-start"
      >
        {t("ctas.TakeYourPhoto")}
      </Button>
      <Button
        className={classes.buttons}
        onClick={onLearnHow}
        component={Link}
        to="/how-to-use/sweat-patch-overview"
      >
        {t("ctas.HowToUse")}
      </Button>
    </>
  );
  return (
    <div className={classes.root}>
      <Div100vh className={classes.intro}>
        <LanguageSelector />
        <div className={classes.top}>
          <GxLogo />
          <div className={classes.welcome}>
            <Typography variant="h1">{t("intro.WelcomeTo")}</Typography>
          </div>
        </div>

        <div className={classes.introCtas}>{ctas}</div>

        <div
          className={classes.getBelowFold}
          onClick={() => scrollToRef(aboutPatchRef)}
        >
          {t("about.GxSweatPatch")}
          <DownIcon />
        </div>
      </Div100vh>
      <section ref={aboutPatchRef} className={classes.aboutSection}>
        <div className={classes.hero}>
          <Typography variant="h3">{t("about.heroText")}</Typography>
        </div>
        <div className={classes.heroImg}>
          <img src={Strength} alt="Strength" />
        </div>

        <div className={classes.content}>
          <Typography variant="overline">{t("overview.title")}</Typography>
          <Typography variant="h3">{t("overview.description")}</Typography>

          <div className={classes.patchFeatures}>
            <img
              src={FluidLoss}
              alt={t("overview.fluidLossTitle")}
              width="30"
              height="10"
            />

            <Typography variant="subtitle1">
              {t("overview.fluidLossTitle")}
            </Typography>

            <Typography variant="body1">
              {t("overview.fluidLossDesc")}
            </Typography>
          </div>

          <div className={classes.patchFeatures}>
            <img
              src={SweatRate}
              alt={t("overview.sweatRateTitle")}
              width="30"
              height="10"
            />

            <Typography variant="subtitle1">
              {t("overview.sweatRateTitle")}
            </Typography>

            <Typography variant="body1">
              {t("overview.sweatRateDesc")}
            </Typography>
          </div>

          <div className={classes.patchFeatures}>
            <img
              src={SodiumLoss}
              alt={t("overview.sodiumLossTitle")}
              width="30"
              height="10"
            />

            <Typography variant="subtitle1">
              {t("overview.sodiumLossTitle")}
            </Typography>

            <Typography variant="body1">
              {t("overview.sodiumLossDesc")}
            </Typography>
          </div>
        </div>

        <div className={classes.content}>
          <Typography variant="overline">{t("howItWorks.title")}</Typography>

          <div
            style={{ position: "relative", height: 380, overflow: "hidden" }}
          >
            <img
              src={Workout}
              style={{ width: "100%", position: "absolute" }}
              alt={t("howItWorks.title")}
            />
            <div
              style={{
                color: colors.white,
                position: "absolute",
                left: 24,
                top: 24,
              }}
            >
              <Typography variant="h3">
                {t("howItWorks.workouts")}
                <br />
                30+ min
                <br />8 - 35&deg;C
              </Typography>
            </div>
          </div>

          <Typography variant="h3">{t("howItWorks.caption")}</Typography>
          <Typography variant="body1">{t("howItWorks.description")}</Typography>
        </div>

        <div
          className={classes.content}
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.25)" }}
        >
          <Typography variant="overline">{t("sweatPatchQnA.title")}</Typography>

          <FAQ questions={faq} className={classes.faq} />
        </div>

        <div className={classes.content}>
          <Typography variant="overline">{t("poweredBy.title")}</Typography>

          <img
            src={Epicore}
            alt="Epicore Biosystems"
            width="113"
            style={{ marginBottom: 40 }}
          />
        </div>

        <div className={classes.ctas}>{ctas}</div>
      </section>
    </div>
  );
}

export default withStyles(styles)(SplashScreen);
