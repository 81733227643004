import React from 'react'
import { colors } from '../theme'
import { withStyles, createStyles } from '@material-ui/core'
const styles = createStyles({
  root: {
    marginTop: 24,
    marginBottom: 24,
    width: '100%',
    borderColor: colors.grey,
    borderWidth: 1,
  },
})

function Divider({classes, className}:any) { 
  return <hr className={`${classes.root} ${className}`}/> 
}

export default withStyles(styles)(Divider)
